import React, { useState } from "react";
import {
  greenPhoneTrackingCircle,
  phoneTrackingCircle
} from "../../../assets/images";
import { t } from "i18next";
import TransactionDeclinedModal from "../../transactionDeclined/transactionDeclined";
import ModalComponent from "../../../components/common/Modal/modal";
import ProgressCircle from "../../../components/common/progressCircle/progressCircle";
import { getSubscriptionColor } from "../../../utils/commonUtils";

const SubscriptionListPage = ({
  subscriptionList,
  handleCancelSubscription,
  handlePendingCancel,
  handleSubscription,
  onRetry,
  retryModal,
  setRetryModal,
  stripeError,
  setStripeError,
  isLoading
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(false);
  return (
    <>
      <div className="wpb_content_subs account_bg_wrap">
        <div className="account_title">
          <h2>{subscriptionList?.length > 1 ? t("PR_SUBSCRIPTIONS") : t("PRICING_SUBSCRIPTION_TAG")}</h2>
        </div>
        <div className="subscriptions_table">
          {subscriptionList?.length > 0 ? 
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{t("PR_PRODUCT")}</th>
                <th>{t("PR_FREQUENCY")}</th>
                <th>{t("PR_NEXT_INVOICE")}</th>
                <th>{t("PR_USES")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {subscriptionList?.map((subscription) => (
                <tr key={subscription.id}>
                  <td>
                    <ProgressCircle
                      width={40}
                      height={40}
                      percentage={Math.floor(subscription?.payment_date_percentage)}
                      color={getSubscriptionColor(subscription.name)}
                    />
                  </td>
                  <td data-title="Product">
                    <span>
                      {subscription.name}
                      {subscription.status !== "canceled" && (
                        <button className="tracking_btn">
                          {subscription.status === "trialing"
                            ? `${t('PR_TRIAL_ENDS')} ${subscription.trial_end_date}`
                            : `${t('PR_ENDS')} ${subscription.end_date}`}
                        </button>
                      )}
                    </span>
                  </td>
                  <td data-title="Frequency">{t('PR_BILLING_MONTHLY')}</td>
                  <td data-title="Next Invoice">
                    {subscription.is_cancelled || subscription.is_expired
                      ? "N/A"
                      : `${subscription.next_invoice} for ${subscription.symbol} ${subscription.amount}`}
                  </td>
                  <td data-title="Uses">
                    {subscription.limit >= 0
                      ? `${10 - subscription.limit} / 10  ${t("REPORTS")}`
                      : "N/A"}
                  </td>
                  <td className="cancel_subscription">
                    <button
                      className="cancel_btn"
                      onClick={() => {
                        if (subscription.status === "canceled") {
                          handleSubscription(subscription, true);
                        } else if (
                          subscription.name === "Unlock Full Report" &&
                          subscription.limit === 0
                        ) {
                          handleSubscription(subscription);
                        } else if (
                          (subscription.status === "active" ||
                            subscription.status === "trialing") &&
                          !subscription.sub_status
                        ) {
                          setConfirmModal(true);
                          setSubscriptionData(subscription);
                        } else if (
                          subscription.status === "active" &&
                          subscription.sub_status === "pending_unsubscribe"
                        ) {
                          handlePendingCancel(subscription?.id);
                        }
                      }}
                      disabled={isLoading}
                    >
                      {subscription.status === "canceled"
                        ? t("PR_REACTIVATE_1")
                        : subscription.name === "Unlock Full Report" &&
                          subscription.limit === 0
                          ? t("PR_ADD_MORE_REPORT")
                          : (subscription.status === "active" ||
                            subscription.status === "trialing") &&
                            !subscription.sub_status
                            ? t('CANCEL_SUBSCRIPTION')
                            : subscription.status === "active" &&
                              subscription.sub_status === "pending_unsubscribe"
                              ? t("PR_REACTIVATE")
                              : ""}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
            : <p>{t('PR_NO_RECORDS_FOUND')}</p>}
        </div>
      </div>
      <ModalComponent
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
        closable={false}
        className="tracelo_unsubscribe-modal"
      >
        <div className="login-modal-section">
          <div className="unsubscribe_title">
            <h2>{t("MENU_UNSUBSCRIBE")}</h2>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">
              {t("PR_UNSUBSCRIBE_POPUP_MESSAGE")}
            </div>
            <div className="submit-btn-modal">
              <button
                onClick={() => {
                  setConfirmModal(false);
                  handleCancelSubscription(subscriptionData?.id);
                }}
              >
                {t("UNSUBSCRIBE_SUBMIT_BUTTON")}
              </button>
            </div>
            <div className="back-btn-modal">
              <button onClick={() => setConfirmModal(false)}>
                {t("PR_CANCELLATION_BACK_BUTTON")}
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
      <TransactionDeclinedModal
        isOpen={retryModal}
        onClose={() => (setRetryModal(false), setStripeError(""))}
        closable={false}
        handleSubmit={onRetry}
        stripeError={stripeError}
      />
    </>
  );
};

export default SubscriptionListPage;
