/* global gtag */
import React, { useContext, useEffect, useState } from "react";
import {
  faqsIcon,
  headerNotificationBell,
  menuAccountIcon,
  menuContactIcon,
  menuDashboardIcon,
  newHeaderLogo,
  notificationBellIcon,
  user_profile,
} from "../../../assets/images";
import "./header.scss";
import { Drawer, Dropdown, Input, Modal, notification, Spin } from "antd";
import { IoCloseCircle, IoSearch } from "react-icons/io5";
import { TbLogout, TbHelp } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import { checkMonitorSocket } from "../../../redux/slice/checkMonitorSocketSlice";
import { useTranslation } from "react-i18next";
import Notify from "../../../../components/common/Notify/notify";
import { connect, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumberFromString, {
  isValidPhoneNumber,
} from "libphonenumber-js";
import {
  checkIsPhoneNmberExists,
  createLocation,
  sendLocation,
} from "../../../redux/slice";
import {
  handleSetNumber,
  handleSetPhoneNumber,
  renderRTLClass,
} from "../../../../utils/commonUtils";
import io from "socket.io-client";
import { ProcessModalContext } from "../../../context/processModalProvider";
import { IoNotificationsCircle } from "react-icons/io5";
import { NotificationList } from "../../../redux/slice/notificationListSlice";
import { formateTime } from "../../../utils/commonUtils";
import { readNotification } from "../../../redux/slice/readNotificationSlice";
import { GoDotFill } from "react-icons/go";
import { notificationStatistics } from "../../../redux/slice/notificationStatisticsSlice";
import { useAppContextInput } from "../../../../App";
import Loader from "../../common/loader/loader";
import { socket_listen_key } from "../../../../environment";

const socket = io(socket_listen_key);

const PhoneReportHeader = ({
  authenticate,
  sendDetailsData,
  callCheckIsPhoneNmberExists,
  callCreateLocation,
  callsendLocation,
  callNotificationList,
  notificationList,
  callReadNotification,
  monitorSocket,
  callNotificationStatiStics,
  notificationStatistics,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const { email: userEmail, _id: userId } =
    authenticate?.authenticateData?.data || "";
  const [openDrawer, setOpenDrawer] = useState(false);
  const [token, setToken, removeToken] = useCookies(["token"]);
  const [roles, setRole, removeRole] = useCookies(["role"]);
  const [step, setStep, removeStep] = useCookies(["step"]);
  const [seephoneReport, setphoneReport, removephoneReport] = useCookies([
    "phoneReport",
  ]);

  const location = useLocation();
  const [loading, isLoading] = useState(false);

  const [countryShortName, setCountryShortName] = useState("");
  const [numberError, setNumberError] = useState("");
  const [numberValidate, setNumberValidate] = useState("");
  const [countryCode, setCountryCode] = useState(+1);
  const [number, setNumber] = useState("");
  const { closeProcessModal, openProcessModal } =
    useContext(ProcessModalContext);
  const [notificationModal, setNotificationModal] = useState(false);
  const notificationData = notificationList?.notificationList?.data;

  useEffect(() => {
    if (countryCode.toString().includes("+")) {
      setNumber(countryCode.replace("+", ""));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      sendDetailsData &&
      sendDetailsData.getSendDetailsData &&
      sendDetailsData.getSendDetailsData.data
    ) {
      setCountryShortName(sendDetailsData.getSendDetailsData.data.country);
    }
  }, [sendDetailsData]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleNavigate = (path) => {
    navigate(`/${lang}/${path}`);
    openDrawer && handleCloseDrawer();
  };

  const handleLogout = () => {
    removeToken(["token"]);
    removeRole(["role"]);
    removeStep(["step"]);
    removephoneReport(["phoneReport"]);
    const cookieKeys = Array.isArray(cookies.getAll())
      ? cookies.getAll()
      : Object.keys(cookies.getAll());
    cookieKeys.forEach((key) => {
      if (key !== "currency") {
        cookies.remove(key, { path: "/" });
      }
    });
    window.location.href = "/";
  };

  function isActiveRoute(route) {
    const currentPath = location.pathname;
    return currentPath.includes(route);
  }

  const items = [
    {
      key: "1",
      label: (
        <div
          className={`mobile_menu_option ${
            isActiveRoute("dashboard") && "active"
          }`}
          onClick={() => handleNavigate("dashboard")}
        >
          <img src={menuDashboardIcon} alt="" />
          <span>{t("PR_DASHBOARD")}</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className={`mobile_menu_option ${
            isActiveRoute("my-account") && "active"
          }`}
          onClick={() => handleNavigate("my-account")}
        >
          <img src={menuAccountIcon} alt="" />
          <span>{t("PR_ACCOUNT")}</span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className={`mobile_menu_option ${
            isActiveRoute("contact-us") && "active"
          }`}
          onClick={() => handleNavigate("contact-us")}
        >
          <img src={menuContactIcon} alt="" />
          <span>{t("CONTACT_US_TITLE")}</span>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          className={`mobile_menu_option ${isActiveRoute("faqs") && "active"}`}
          onClick={() => handleNavigate("faqs")}
        >
          <TbHelp />
          <span>{t("PR_FAQ")}</span>
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div className="mobile_menu_option" onClick={handleLogout}>
          <TbLogout />
          <span>{t("PR_LOGOUT_PHONE_REPORT")}</span>
        </div>
      ),
    },
  ];

  // Send New Number Flow
  const { inputRef } = useAppContextInput();

  const [dialCode, setDialCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [finalFormattedNumber, setFinalFormattedNumber] = useState();
  const [countryCodeinput, setCountryCodeinput] = useState("ca");
  const [resultError, setResultError] = useState("");
  const [formatedNumber, setFormatedNumber] = useState(0);

  const handleSendMessage = async (
    isCreateLocation,
    senderNumber,
    dialCode
  ) => {
    const data = {
      sender_country_code: "+" + dialCode,
      sender_mobile_number: senderNumber,
    };
    isLoading(true);
    let result;
    if (isCreateLocation) {
      isLoading(true);
      result = await callCreateLocation(data);
      const { payload } = result || {};
      const { data: responseData } = payload;
      const { _id } = responseData;
      isLoading(false);
      navigate(
        `/${lang}/access-report?D=${responseData.phone_number_prefix}&n=${responseData.phone_number}&id=${_id}`
      );

      Notify("success", t("NOTIFY_SEND_LOCATION"), "");
    } else {
      isLoading(true);
      result = await callsendLocation(data);
      isLoading(false);
    }
    if (result.type === "sendLocation/fulfilled") {
      Notify("success", t("NOTIFY_SEND_LOCATION"), "");
      document.body.style.overflow = "unset";
      isLoading(false);
      navigate(`/${lang}/access-report`);
    } else if (result.type === "sendLocation/rejected") {
      setResultError(result.error.message);
      document.body.style.overflow = "unset";
      isLoading(false);
    }
  };
  const handleLocateNewNumber = async (
    dialCode,
    passedPhoneNumber,
    finalFormattedNumber
  ) => {
    setNumberError(" ");
    const parsedNumber = parsePhoneNumberFromString(
      passedPhoneNumber,
      countryShortName.replace("+", "").toUpperCase()
    );

    let trimmedNumber = finalFormattedNumber.trim();
    let formatRemove = finalFormattedNumber.trim();
    if (trimmedNumber.startsWith(dialCode)) {
      trimmedNumber = trimmedNumber.substring(dialCode.length).trim();
      if (trimmedNumber.length < 10) {
        if (dialCode.length <= 2) {
          trimmedNumber = dialCode + trimmedNumber;
          formatRemove = dialCode + formatRemove;
        }
      }
    }
    if (trimmedNumber.startsWith(0)) {
      trimmedNumber = trimmedNumber.substring(1).trim();
    }
    formatRemove = trimmedNumber
      .replaceAll(" ", "")
      .replace("-", "")
      .replace("(", "")
      .replace(")", "");
    setFormatedNumber(`+${dialCode} ${trimmedNumber}`);
    if (passedPhoneNumber && passedPhoneNumber !== "0") {
      if (
        parsedNumber &&
        parsedNumber.isValid() &&
        parsedNumber.country === countryShortName.replace("+", "").toUpperCase()
      ) {
        setNumberValidate("");
        const data = {
          phone_number_prefix: "+" + dialCode,
          phone_number: formatRemove,
        };

        isLoading(true);
        const result = await callCheckIsPhoneNmberExists(data);
        if (result.type === "checkIsPhoneNmberExists/fulfilled") {
          const { success, info } = result.payload.data || {};
          const { _id } = info || {};
          if (!success && passedPhoneNumber) {
            handleSendMessage(!success, formatRemove, dialCode);
          } else {
            if (passedPhoneNumber && passedPhoneNumber !== 0) {
              if (
                isValidPhoneNumber(
                  passedPhoneNumber,
                  `${
                    countryShortName
                      ? countryShortName.toUpperCase()
                      : countryCodeinput.toUpperCase()
                  }`
                )
              ) {
                navigate(
                  `/${lang}/access-report?D=${info.phone_number_prefix}&n=${info.phone_number}&id=${_id}`
                );
                setNumberError("");
              } else {
                setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
              }
            } else {
              setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
            }
          }
        } else {
          Notify("error", result.error.message, "");
        }
        isLoading(false);
      } else {
        setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
      }
    } else {
      setNumberValidate(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };

  const handleNewNumberFind = () => {
    if (finalFormattedNumber) {
      handleLocateNewNumber(dialCode, phoneNumber, finalFormattedNumber);
    } else {
      setNumberValidate(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };

  // Socket Events for the real time notification for monitoring

  useEffect(() => {
    if (monitorSocket?.checkMonitorSocket?.data?.phone_report_ids?.length > 0) {
      const handleDataReceived = async (data) => {
        console.log("Socket call from header for monitoring", data);
        const {
          data: { phone_report_id, phone_number, phone_number_prefix, type },
          notificationId,
        } = data;
        notification.success({
          message: t("PR_NOTIFICATION_MONITORING_TITLE"),
          description: t("PR_NOTIFICATION_MONITORING_DESCRIPTION", {
            phone_number: phone_number_prefix + phone_number,
          }),
          placement: "topRight",
          onClick: () => {
            navigate(
              `/${lang}/access-report?D=${phone_number_prefix}&n=${phone_number}&id=${phone_report_id}`
            );
            notification.destroy();
            (async () => {
              try {
                await callReadNotification(notificationId);
                callNotificationStatiStics("unread");
              } catch (error) {
                console.error(
                  "Error in handling notification read or updating stats:",
                  error
                );
              }
            })();
          },
          showProgress: true,
          className: "report_notification",
          closable: true,
          duration: 10,
          style: { cursor: "pointer" },
        });
      };
      socket.on(userId, handleDataReceived);
      return () => {
        socket.off(userId, handleDataReceived);
      };
    }
    setPhoneNumber("");
    setNumberError("");
    setNumberValidate("");
  }, [userId, location.pathname]);

  // Socket Events for the real time notification

  useEffect(() => {
    const handleDataReceived = async (data) => {
      console.log("Socket call from header", data);
      callNotificationStatiStics("unread");
      if (!location.pathname.includes("access-report")) {
        const {
          data: { phone_report_id, phone_number, phone_number_prefix },
          notificationId,
        } = data;
        notification.success({
          message: t("PR_NOTIFICATION_RETRIVAL_TITLE"),
          description: t("PR_NOTIFICATION_RETRIVAL_DESCRIPTION", {
            phone_number: phone_number_prefix + phone_number,
          }),
          placement: "topRight",
          onClick: () => {
            navigate(
              `/${lang}/access-report?D=${phone_number_prefix}&n=${phone_number}&id=${phone_report_id}`
            );
            notification.destroy();
            (async () => {
              try {
                await callReadNotification(notificationId);
                callNotificationStatiStics("unread");
              } catch (error) {
                console.error(
                  "Error in handling notification read or updating stats:",
                  error
                );
              }
            })();
          },
          showProgress: true,
          className: "report_notification",
          closable: true,
          duration: 10,
          style: { cursor: "pointer" },
        });
      }
    };
    socket.on(userId, handleDataReceived);
    return () => {
      socket.off(userId, handleDataReceived);
    };
  }, [userId, location.pathname]);

  const handleOpenChange = (flag) => {
    setNotificationModal(flag);
    if (flag) {
      callNotificationList();
    }
  };

  const menu = (
    <div
      className="NotificationDropDownArea"
      style={{
        width: 300,
        padding: "10px",
        minHeight: 300,
        overflowY: "auto",
        maxHeight: 300,
        backgroundColor: "#fff",
      }}
    >
      {notificationList.isLoading ? (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: 300,
            width: "100%",
            maxWidth: "calc(304px - 20px) !important",
          }}
        >
          <Spin size="small" />
        </div>
      ) : (
        <>
          {notificationData?.length > 0 ? (
            notificationData
              ?.slice()
              .reverse()
              .map((item, index) => {
                const {
                  phone_number_prefix,
                  phone_number,
                  phone_report,
                  createdAt,
                  is_read,
                  _id,
                } = item || {};
                return (
                  <div
                    className="NotificationDropDownBox"
                    key={index}
                    onClick={() => {
                      if (phone_number) {
                        navigate(
                          `/${lang}/access-report?D=${phone_number_prefix}&n=${phone_number}&id=${phone_report}`
                        );
                        setNotificationModal(false);
                        (async () => {
                          if (!is_read) {
                            try {
                              await callReadNotification(_id);
                              callNotificationStatiStics("unread");
                            } catch (error) {
                              console.error(
                                "Error in handling notification read or updating stats:",
                                error
                              );
                            }
                          }
                        })();
                      }
                    }}
                  >
                    <div
                      className="wpb__NotificationDropDown"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <img src={notificationBellIcon} alt="" />
                      <div className="wpb__NotificationCenter">
                        {phone_number && (
                          <h5>{`${phone_number_prefix} ${phone_number}`}</h5>
                        )}
                        <p>
                          {t("PR_NEW_INFORMATION_AVAILABLE")}{" "}
                          {phone_number_prefix && phone_number
                            ? `${phone_number_prefix} ${phone_number}`
                            : "User"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="unread_NotificatiobWrapper"
                      style={{ textAlign: "right" }}
                    >
                      <p
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          color: is_read ? "#4CAF50" : "#F4433680",
                        }}
                      >
                        {!is_read && <GoDotFill color="#24537B" size={20} />}
                      </p>
                      <span style={{ fontSize: "12px", color: "#999" }}>
                        {formateTime(createdAt)}
                      </span>
                    </div>
                  </div>
                );
              })
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 300,
              }}
            >
              {t("PR_NO_NOTIFICATION_FOUND")}
            </div>
          )}
        </>
      )}
    </div>
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (token?.token) {
      dispatch(checkMonitorSocket());
      callNotificationStatiStics("unread");
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      <header className="header_wrap">
        <div className="main_container_header">
          <div className="header_wrap_area">
            <div
              className="header_logo"
              onClick={() => handleNavigate("dashboard")}
            >
              <img src={newHeaderLogo} alt="" width={200} height={45} />
            </div>
            <div className="header_functionality">
              <div className="searchbar_wrapper search-animation-set">
                <PhoneInput
                  country={countryShortName.toLowerCase() || "ca"}
                  onChange={(value, country) => {
                    handleSetNumber(
                      value,
                      country,
                      setDialCode,
                      setNumberValidate,
                      setCountryShortName,
                      setPhoneNumber,
                      setFinalFormattedNumber,
                      phoneNumber
                    );
                  }}
                  placeholder={t("BANNER_INPUT")}
                  inputProps={{
                    name: "phone",
                    disabled: true,
                  }}
                  onEnterKeyPress={handleNewNumberFind}
                  countryCodeEditable={false}
                  autoFormat={true}
                  enableSearch={true}
                  searchStyle={{
                    width: "100%",
                    height: "35px",
                    borderRadius: "7px",
                    margin: "0px",
                    padding: "0px; !important",
                  }}
                  disableSearchIcon={true}
                  searchPlaceholder={t("SEARCH")}
                  searchNotFound={t("NO_MATCHED")}
                />
                <Input
                  ref={inputRef}
                  className={`input-form form-control mobile_search_input`}
                  onChange={(event) => {
                    setNumberValidate("");
                    handleSetPhoneNumber(
                      event,
                      setPhoneNumber,
                      setNumberValidate,
                      setCountryShortName,
                      countryShortName,
                      setDialCode,
                      setFinalFormattedNumber,
                      inputRef
                    );
                  }}
                  placeholder={t("BANNER_INPUT")}
                  maxLength="20"
                  type="text"
                  inputMode="tel"
                  value={phoneNumber}
                  id="phone_input"
                  onPressEnter={handleNewNumberFind}
                />
                <IoSearch
                  className="search-animation-search-icon"
                  onClick={handleNewNumberFind}
                />
                {numberError && <p className="Number_Error">{numberError}</p>}
                {numberValidate && (
                  <p className="Number_Error">{numberValidate}</p>
                )}
              </div>
              <div className="NotificationCenterWrap">
                {notificationStatistics?.data?.count > 0 && (
                  <span>{notificationStatistics?.data?.count}</span>
                )}
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  overlayClassName={`${renderRTLClass()} notificationAddressinfo`}
                  open={notificationModal}
                  onOpenChange={handleOpenChange}
                  placement="bottomRight"
                  overlayStyle={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    minWidth: "unset !important",
                  }}
                >
                  <img src={headerNotificationBell} alt="" />
                </Dropdown>
              </div>
              <div className="searchbar_wrapper mobile-drawer">
                <div className="openbtn" onClick={() => setOpenDrawer(true)}>
                  <div className="openbtn-area">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>

              <div className="searchbar_wrapper desktop-drawer">
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomRight"
                  trigger={["click"]}
                  overlayClassName={`dropdown-setting ${renderRTLClass()}`}
                >
                  <div>
                    <div
                      className="openbtn"
                      onClick={(e) => e.target.classList.toggle("active")}
                    >
                      <div className="openbtn-area">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className="mobile-header">
              <PhoneInput
                country={countryShortName.toLowerCase() || "ca"}
                className="phone-input"
                onChange={(value, country) => {
                  handleSetNumber(
                    value,
                    country,
                    setDialCode,
                    setNumberValidate,
                    setCountryShortName,
                    setPhoneNumber,
                    setFinalFormattedNumber,
                    phoneNumber
                  );
                }}
                inputProps={{
                  name: "phone",
                  required: true,
                  className: "form-control input-field",
                }}
                placeholder={t("BANNER_INPUT")}
                onEnterKeyPress={handleNewNumberFind}
                countryCodeEditable={false}
                autoFormat={false}
                enableSearch={true}
                searchStyle={{
                  width: "100%",
                  height: "35px",
                  borderRadius: "7px",
                  margin: "0px",
                  padding: "0px; !important",
                }}
                disableSearchIcon={true}
                searchPlaceholder={t("SEARCH")}
                searchNotFound={t("NO_MATCHED")}
              />
              <Input
                ref={inputRef}
                className={`form-control input-field header_mobile_number_input`}
                onChange={(event) => {
                  setNumberValidate("");
                  handleSetPhoneNumber(
                    event,
                    setPhoneNumber,
                    setNumberValidate,
                    setCountryShortName,
                    countryShortName,
                    setDialCode,
                    setFinalFormattedNumber,
                    inputRef
                  );
                }}
                placeholder={t("BANNER_INPUT")}
                maxLength="20"
                type="text"
                inputMode="tel"
                value={phoneNumber}
                id="phone_input"
                onPressEnter={handleNewNumberFind}
              />
              <IoSearch
                className="mobile-header-search-icon"
                onClick={handleNewNumberFind}
              />
              {numberError && (
                <p className="mobile-number-error">{numberError}</p>
              )}
              {numberValidate && (
                <p className="mobile-number-error">{numberValidate}</p>
              )}
            </div>
          </div>
        </div>
        <Drawer
          title="Title"
          placement={"right"}
          closable={false}
          onClose={handleCloseDrawer}
          open={openDrawer}
          key={"right"}
          className="mobile-drawer"
          maskClosable={false}
          extra={
            <button onClick={handleCloseDrawer} className="menu_close_btn">
              <IoCloseCircle />
            </button>
          }
        >
          <div className="profile_icon">
            <img src={user_profile} alt="" />
            <p>{userEmail}</p>
          </div>
          <div className="mobile-drawer-menu">
            <div
              className={`mobile_menu_option ${
                isActiveRoute("dashboard") && "active"
              }`}
              onClick={() => handleNavigate("dashboard")}
            >
              <img src={menuDashboardIcon} alt="" />
              <span>{t("PR_DASHBOARD")}</span>
            </div>
            <div
              className={`mobile_menu_option ${
                isActiveRoute("my-account") && "active"
              }`}
              onClick={() => handleNavigate("my-account")}
            >
              <img src={menuAccountIcon} alt="" />
              <span>{t("PR_ACCOUNT")}</span>
            </div>
            <div
              className={`mobile_menu_option ${
                isActiveRoute("contact-us") && "active"
              }`}
              onClick={() => handleNavigate("contact-us")}
            >
              <img src={menuContactIcon} alt="" />
              <span>{t("CONTACT_US_TITLE")}</span>
            </div>
            <div
              className={`mobile_menu_option ${
                isActiveRoute("faqs") && "active"
              }`}
              onClick={() => handleNavigate("faqs")}
            >
              <img src={faqsIcon} alt="" />
              <span>{t("PR_FAQ")}</span>
            </div>
            <div className="mobile_menu_option" onClick={handleLogout}>
              <TbLogout />
              <span>{t("PR_LOGOUT_PHONE_REPORT")}</span>
            </div>
          </div>
        </Drawer>
      </header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticate: state.authenticate,
    sendDetailsData: state.sendDetailsData,
    notificationList: state.notificationList,
    monitorSocket: state.monitorSocket,
    notificationStatistics:
      state.notificationStatistics?.notificationStatistics,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callsendLocation: (data) => dispatch(sendLocation(data)),
    callCheckIsPhoneNmberExists: (data) =>
      dispatch(checkIsPhoneNmberExists(data)),
    callCreateLocation: (data) => dispatch(createLocation(data)),
    callNotificationList: (data) => dispatch(NotificationList(data)),
    callReadNotification: (data) => dispatch(readNotification(data)),
    callNotificationStatiStics: (data) =>
      dispatch(notificationStatistics(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneReportHeader);
