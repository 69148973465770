import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import {
  AccessReport,
  MyAccount,
  PhoneReportDashboard,
  ContactUs,
  FaqComponent,
  LocationTrack
} from "./pages";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {stripeLoadScript} from "../environment";
import {bigLanguages} from "./utils/commonUtils";

const PhoneReportsRoutes = () => {
  const stripePromise = loadStripe(stripeLoadScript);
  const location = useLocation();

  useEffect(() => {
    const pathnameParts = location.pathname.split("/");
    const currentLang = pathnameParts[1];
    // Apply RTL direction and unique class if the language is "ar" or "he"
    const rootElementRoot = document.getElementById("root");
    if (bigLanguages.includes(currentLang)) {
      rootElementRoot.classList.add("high__lang_wrapper");
    } else {
      rootElementRoot.classList.remove("high__lang_wrapper");
    }
  }, [location.pathname]);

  return (
    <>
      <Route
        path="/:lang/access-report"
        element={
          <Elements stripe={stripePromise}>
            <AccessReport />
          </Elements>
        }
      ></Route>
      <Route
        path="/:lang/my-account"
        element={
          <Elements stripe={stripePromise}>
            <MyAccount />
          </Elements>
        }
      ></Route>
      <Route path="/:lang/dashboard" element={<PhoneReportDashboard />}></Route>
      <Route path="/:lang/contact-us" element={<ContactUs />}></Route>
      <Route path="/:lang/faqs" element={<FaqComponent />}></Route>
      <Route path="/:lang/location" element={<LocationTrack />}></Route>
    </>
  );
};

export default PhoneReportsRoutes;
