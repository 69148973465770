import React from "react";
import "./ReportAccessModal.scss";
import ModalComponent from "../../../common/Modal/modal";
import checkmark from "../../../../lottieAnimations/animationJson/checkmark.json";
import Lottie from "react-lottie";
import { t } from "i18next";

const ReportAccessModal = (props) => {
  const { isOpen, onClose, handleViewReport } = props;
  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} closable={false} maskClosable={false}>
      <div className="report_prepared__modal unlock__report_processs">
        <div className="modal__wpl__text">
          <h2 className="modal__popup_title">{t("PR_REPORT_PREPARED")}</h2>
          <p className="modal__popup_desc">
          {t("PR_REPORT_READY_DESCRIPTION")}
          </p>
        </div>
        <div className="report_prepared__img">
          <Lottie
            options={{ animationData: checkmark }}
            animation
            height={220}
            width={220}
          />
        </div>
        <div class="report__popup_pay_btn">
          <p>
            <button className="mb-0" type="submit" onClick={handleViewReport}>
            {t("PR_VIEW_REPORT")}
            </button>
          </p>
        </div>
      </div>
    </ModalComponent>
  );
};
export default ReportAccessModal;
