import { Switch } from "antd";
import React, { useState } from "react";
import MonitoringReportModal from "../Modals/MonitoringReportModal/MonitoringReportModal";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../../../components/common/Notify/notify";
import Loader from "../../common/loader/loader";
import { getPhoneReportDetails } from "../../../redux/slice";
import { updateMonitoring } from "../../../redux/slice/updateMonitoringSlice";

const MonitorSwitch = () => {
  const dispatch = useDispatch();
  const { is_monitor, phoneReport } = useSelector(
    (state) => state.getPhoneReportDetails?.getPhoneReportDetailsData?.data
  );
  const [monitorModal, setMonitorModal] = useState(false);
  const [isLoading, setLoading] = useState(false);


  const handleMonitoringReport = async () => {
    setMonitorModal(false);
    setLoading(true);
    const payload = {
      phone_report_id: phoneReport?._id,
      is_monitor: !is_monitor,
      // monitor_duration_minutes: 1 // For testing in local to run cron in given time
    };
    try {
      const res = await dispatch(updateMonitoring(payload));
      const msg =
        res.type === "updateMonitoring/fulfilled"
          ? res.payload.message
          : res.error.message || "An error occurred";
      Notify(
        res.type === "updateMonitoring/fulfilled" ? "success" : "error",
        msg,
        ""
      );
      if (res.type === "updateMonitoring/fulfilled") {
        await dispatch(getPhoneReportDetails(phoneReport?._id));
      }
    } catch (error) {
      Notify("error", error.message || "Something went wrong", "");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Switch
        checked={is_monitor}
        onChange={() =>
          is_monitor ? handleMonitoringReport() : setMonitorModal(true)
        }
      />
      {monitorModal && (
        <MonitoringReportModal
          isOpen={monitorModal}
          setMonitorModal={setMonitorModal}
          onChangeMonitor={handleMonitoringReport}
        />
      )}
    </>
  );
};

export default MonitorSwitch;
