import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const checkMonitorSocket = createAsyncThunk(
  "checkMonitorSocket",
  async () => {
    try {
      const response = await AxiosInstance.get(
        `/phone_report/monitoring/active_sockets`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const checkMonitorSocketSlice = createSlice({
  name: "checkMonitorSocket",
  initialState: {
    isLoading: false,
    checkMonitorSocket: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [checkMonitorSocket.pending]: (state) => {
      state.isLoading = true;
    },
    [checkMonitorSocket.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.checkMonitorSocket = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [checkMonitorSocket.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default checkMonitorSocketSlice.reducer;
