import React from "react";
import footerLogo from "../../assets/newhome/footerLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { useAppContextInput } from "../../App";
import "./footer.scss";

const FooterComponent = (props) => {
  const { backURL, footerSignUpKey } = props; // Retrieve any necessary props
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const { inputRef, mobileInputRef, handleInputFocus } = useAppContextInput();

  const handleNavigate = () => {
    navigate(`/${lang}`);
    if (window.screen.width > 767) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      if (mobileInputRef && mobileInputRef.current) {
        mobileInputRef.current.focus();
      }
    }
  };

  return (
    <div className="SiteFooter_Wrapper">
      <div className="NewContainer">
        <div className="footerContain_wrapper">
          {/* Logo and Description */}
          <div className="FooterLogo__area">
            <div className="FooterLogo_Wrap">
              {backURL ? (
                <Link to={`/${lang}/tracking`}>
                  <img src={footerLogo} alt="Footer Logo" />
                </Link>
              ) : (
                <Link to={`/${lang}/`}>
                  <img src={footerLogo} alt="Footer Logo" />
                </Link>
              )}
            </div>
            <p className="FooterDescription">{t("PR_FOOTER_DESC")}.</p>
          </div>

          {/* Menu Section */}
          <div className="FooterMenu__list">
            {/* Legal Info Section */}
            <div className="MenuIteam__List SubMenu__LegalList">
              <h2 className="FooterMenu__title">{t("FOOTER_LEGAL_TITLE")}</h2>
              <ul>
                <li>
                  <Link to={`/${lang}/pricing`}>{t("FOOTER_PRICING")}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/contact`}>{t("MENU_CONTACT")}</Link>
                </li>
                <li>
                  <Link to={`/${lang}/faq`}>{t("MENU_FAQ")}</Link>
                </li>
              </ul>
            </div>

            {/* Account Section */}
            <div className="MenuIteam__List SubMenu__AccountList">
              <h2 className="FooterMenu__title">{t("FOOTER_ACCOUNT")}</h2>
              <ul>
                <li>
                  <Link to={`/${lang}/login`}>{t("LOGIN")}</Link>
                </li>
                <li>
                  {footerSignUpKey ? (
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleInputFocus("trial");
                      }}
                    >
                      {t("SIGN_UP")}
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavigate();
                      }}
                    >
                      {t("SIGN_UP")}
                    </Link>
                  )}
                </li>
                <li>
                  <Link to={`/${lang}/unsubscribe`}>
                    {t("MENU_UNSUBSCRIBE")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Footer Bottom - Copyright and Policies */}
        <div className="FooterCopyRight__Info">
          <div className="FooterCopyRight__area">
            <p className="FooterCopyRight_text">Reversly - {currentYear}</p>
          </div>
          <div className="FooterCopyRight__PolicyMenu">
            <ul>
              <li>
                <Link to={`/${lang}/privacy-policy`} target="_blank">
                  {t("FOOTER_PRIVACY_POLICY")}
                </Link>
                <span>|</span>
              </li>
              <li>
                <Link to={`/${lang}/terms`} target="_blank">
                  {t("FOOTER_TERM_CONDITION")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;