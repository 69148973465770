import React, { useState } from "react";
import { t } from "i18next";
import "./generalPhoneInfo.scss";
import { AdditionalInfoModal } from "../../Modals";
import { informationIcon } from "../../../../assets/images";
import { renderValue } from "../../../../utils/commonUtils";

const GeneralPhoneInfo = ({ reportDetail }) => {
  const { general_phone_info } = reportDetail?.phoneReport || {};
  const { data, status } = general_phone_info || {};

  const [infoModal, setInfoModal] = useState(false);
  return (
    <>
      <div
        className="general-info-wrap white-bg-wrap"
        id="generalPhoneInformation"
      >
        <div className="main_title">
          <h2>
            {t("PR_PREMIUM_SECTION_TITLE_1")}
            <img
              src={informationIcon}
              alt="Information"
              onClick={() => setInfoModal(true)}
            />
          </h2>
        </div>
        {status === "found" ? (
          <div className="phone-info-wrap">
            <div className="phone-info-box">
              <span>{t("PR_VALID_PHONE_NUMBER")}</span>
              <h3>{renderValue(data?.is_valid)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_ACTIVE_NUMBER")}</span>
              <h3>{renderValue(data?.is_active)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_AREA_CODE")}</span>
              <h3>{renderValue(data?.area_code_location)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_NUMBER_LEAKED")}</span>
              <h3>{renderValue(data?.number_leaked)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("COUNTRY")}</span>
              <h3>{renderValue(data?.country)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_PHONE_LINE_TYPE")}</span>
              <h3>{renderValue(data?.line_type || t("PR_STATUS_TYPE"))}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_PHONE_CARRIER_NAME")}</span>
              <h3>{renderValue(data?.carrier_name)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_SPAM_RISK")}</span>
              <h3>{renderValue(data?.spam_risk)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_TIME_ZONE")}</span>
              <h3>{renderValue(data?.timezone)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("VOIP")}</span>
              <h3>{renderValue(data?.is_voip)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_PREPAID_PHONE")}</span>
              <h3>{renderValue(data?.is_prepaid)}</h3>
              <hr />
            </div>
            <div className="phone-info-box">
              <span>{t("PR_REGION")}</span>
              <h3>{renderValue(data?.region)}</h3>
              <hr />
            </div>
          </div>
        ) : (
          <p>{t("PR_NO_RECORDS_FOUND")}</p>
        )}
      </div>
      <AdditionalInfoModal
        isOpen={infoModal}
        onClose={() => setInfoModal(false)}
        closable={true}
        className="multiples_popup__wrap_close_button"
      />
    </>
  );
};

export default GeneralPhoneInfo;
