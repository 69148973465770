import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const externalLogin = createAsyncThunk("externalLogin", async (data) => {
  try {
    const response = await AxiosInstance.post(`/user/external/login`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const externalLoginSlice = createSlice({
  name: "externalLogin",
  initialState: {
    isLoading: false,
    externalLogin: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [externalLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [externalLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.externalLogin = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [externalLogin.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default externalLoginSlice.reducer;