import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const cancelSubscription = createAsyncThunk(
  "cancelSubscription",
  async (subscriptionListId) => {
    try {
      const response = await AxiosInstance.post(`/subscription/cancel`, {
        id: subscriptionListId,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const cancelSubscriptionSlice = createSlice({
  name: "cancelSubscription",
  initialState: {
    isLoading: false,
    cancelSubscription: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [cancelSubscription.pending]: (state) => {
      state.isLoading = true;
    },
    [cancelSubscription.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cancelSubscription = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [cancelSubscription.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default cancelSubscriptionSlice.reducer;
