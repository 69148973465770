import React from "react";
import { Modal } from "antd";
import "./modal.scss";
import { renderRTLClass , } from "../../../../utils/commonUtils";
import {bigLanguagesClass} from '../../../../phoneReport/utils/commonUtils'
const ModalComponent = ({
  isOpen,
  onClose,
  children,
  closable,
  className = "",
  maskClosable = true,
}) => {
  const modalClassName = `multiples_popup__wrap_ ${className} ${renderRTLClass() } ${bigLanguagesClass()}`;
  return (
    <Modal
      centered
      open={isOpen}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={onClose}
      closable={closable}
      className={modalClassName}
      maskClosable={maskClosable}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
