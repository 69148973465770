import React, { useState, useEffect } from "react";
import "./homePage.scss";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import PageBannerContent from "../pageBanner";
import { useAppContextInput } from "../../App";
import HomePageSection from "../reversly/homePageSection/homePageSection";

const HomePageComponent = (props) => {
  const { productList, contant, from } = props;
  const { t } = useTranslation();
  const cookies = new Cookies();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const { fx } = searchNumber;
  const utm_term = fx?.replaceAll("-", " ");

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const cur = cookies.get("currency");
  const lang = cookies.get("lang");
  const [pricing, setPricing] = useState(cur);
  useEffect(() => {
    if (cur) {
      setPricing(cur);
    }
  }, [cur]);
  const langCheck = lang === "ar" || lang === "he";
  const trialProduct = productList?.data.find((item) => item.title === "trial");
  const findPrice = trialProduct?.currency_options.find(
    (item) => item.parameter === pricing
  );

  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionCurr = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === pricing
  );
  const inputs = document.getElementsByClassName("form-control");
  if (inputs && inputs.length > 0) {
    inputs[0] && (inputs[0].disabled = true);
    inputs[1] && (inputs[1].disabled = true);
  }
  const { inputRef, mobileInputRef, handleInputFocus, plan } =
    useAppContextInput();
  // Auto default focus on phone field
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className="homePage-main-section">
      <div className="bg-grad-section">
        {contant !== "none" && (
          <PageBannerContent
            from="none"
            inputRef={inputRef}
            mobileInputRef={mobileInputRef}
            plan={plan}
          />
        )}
      </div>
      <HomePageSection />
    </div>
  );
};

export default HomePageComponent;
