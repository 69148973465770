import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const readNotification = createAsyncThunk(
  "readNotification",
  async (notificationId) => {
    try {
      const response = await AxiosInstance.post(`/notification/mark_as_read`, {
        notification_id: notificationId
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const readNotificationSlice = createSlice({
  name: "readNotification",
  initialState: {
    isLoading: false,
    readNotification: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [readNotification.pending]: (state) => {
      state.isLoading = true;
    },
    [readNotification.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.readNotification = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [readNotification.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default readNotificationSlice.reducer;
