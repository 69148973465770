import React from "react";
import { dsFooterLogo } from "../../../assets/images";
import "./footer.scss";
import { Link } from "react-router-dom";
import { Cookies } from "react-cookie";
import { t } from "i18next";
const PhoneReportFooter = () => {
  const currentYear = new Date().getFullYear();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  return (
    <footer className="site_footer">
      <div className="site_footer_padding">
        <div className="main_container_footer">
          <div className="ds-footer-top-content">
            <p>{t("PR_ACCESS_REPORT_TEXT_2")}</p>
          </div>
          <div className="ds-footer-bottom-content">
            <div className="ds-footer-logo">
              <img src={dsFooterLogo} alt="" />
            </div>
            <div className="ds-copyright-footer">
              <p>
                &copy; {currentYear} - {t("PR_ACCESS_REPORT_TEXT_3")}
              </p>
            </div>
            <div className="ds-footer-conditions">
              <ul>
                <li>
                  <Link to={`/${lang}/privacy-policy`} target="_blank">
                    {t("FOOTER_PRIVACY_POLICY")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/terms`} target="_blank">
                    {t("PR_TC_TERMS_CONDITION")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PhoneReportFooter;
