import { Collapse } from "antd";
import "./faqComponent.scss";
import React, { useState } from "react";
import { accordionMinusIcon, accordionPlusIcon } from "../../assets/images";
import { t } from "i18next";

const FaqComponent = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleCollapseChange = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <div className="main_root">
      <div className="main_container">
        <div className="faqs__accordion_wrapper">
          <div className="faqs__accordion_title">
            <h2>{t("FAQ_ASKED_QUESTION_TITLE")}</h2>
            <p>{t("PR_FAQ_DESCRIPTION")}</p>
          </div>
          <div className="inner__collapse_accordion">
            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "1" ? ["1"] : []}
                onChange={() => handleCollapseChange("1")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("1")}
                  />
                )}
              >
                <Collapse.Panel header={t("PR_QUE_PHONE_REPORT")} key="1">
                  <p>{t("PR_ANS_PHONE_REPORT")}</p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "2" ? ["2"] : []}
                onChange={() => handleCollapseChange("2")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("2")}
                  />
                )}
              >
                <Collapse.Panel
                  header={t("PR_QUE_TRACK_WITHOUT_PHYSICAL_ADDRESS")}
                  key="2"
                >
                  <p>{t("PR_ANS_TRACK_WITHOUT_PHYSICAL_ADDRESS")}</p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "3" ? ["3"] : []}
                onChange={() => handleCollapseChange("3")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("3")}
                  />
                )}
              >
                <Collapse.Panel header={t("PR_QUE_LEGAL_TO_USE")} key="3">
                  <p>{t("PR_ANS_LEGAL_TO_USE")}</p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "4" ? ["4"] : []}
                onChange={() => handleCollapseChange("4")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("4")}
                  />
                )}
              >
                <Collapse.Panel header={t("PR_QUE_NETWORK_SUPPORT")} key="4">
                  {t("PR_ANS_NETWORK_SUPPORT")}
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "5" ? ["5"] : []}
                onChange={() => handleCollapseChange("5")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("5")}
                  />
                )}
              >
                <Collapse.Panel
                  header={t("PR_QUE_TRACK_WITHOUT_MODEL")}
                  key="5"
                >
                  <p>{t("PR_ANS_TRACK_WITHOUT_MODEL")}</p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "6" ? ["6"] : []}
                onChange={() => handleCollapseChange("6")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("6")}
                  />
                )}
              >
                <Collapse.Panel header={t("PR_QUE_TRACK_CELL_PHONE")} key="6">
                  <p>{t("PR_ANS_TRACK_CELL_PHONE")}</p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "7" ? ["7"] : []}
                onChange={() => handleCollapseChange("7")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("7")}
                  />
                )}
              >
                <Collapse.Panel
                  header={t("PR_QUE_TRACK_CELL_PHONE_FREE")}
                  key="7"
                >
                  <p>{t("PR_ANS_TRACK_CELL_PHONE_FREE")}</p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "8" ? ["8"] : []}
                onChange={() => handleCollapseChange("8")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("8")}
                  />
                )}
              >
                <Collapse.Panel header={t("PR_QUE_PAYMENT_DECLINED")} key="8">
                  <p>{t("PR_ANS_PAYMENT_DECLINED")}</p>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqComponent;
