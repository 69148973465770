import React, { createContext, useState } from "react";

export const ProcessModalContext = createContext();

export const ProcessModalProvider = ({ children }) => {
    const [processOpenModalId, setProcessOpenModalId] = useState(null);
    const [successModalId, setSuccessModalId] = useState(null);

    const openProcessModal = (id) => {
        setProcessOpenModalId(id);
    };
    const closeProcessModal = () => {
        setProcessOpenModalId(null);
    };

    const openSuccessModal = (id) => {
        setSuccessModalId(id);
    };

    const closeSuccessModal = () => {
        setSuccessModalId(null);
    };

    return (
        <ProcessModalContext.Provider
            value={{
                processOpenModalId,
                openProcessModal,
                closeProcessModal,
                successModalId,
                closeSuccessModal,
                openSuccessModal
            }}
        >
            {children}
        </ProcessModalContext.Provider>
    );
};
