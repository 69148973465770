import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const notificationStatistics = createAsyncThunk(
  "notificationStatistics",
  async (type) => {
    try {
      const response = await AxiosInstance.get(`/notification/count/${type}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const notificationStatisticsSlice = createSlice({
  name: "notificationStatistics",
  initialState: {
    isLoading: false,
    notificationStatistics: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [notificationStatistics.pending]: (state) => {
      state.isLoading = true;
    },
    [notificationStatistics.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notificationStatistics = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [notificationStatistics.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default notificationStatisticsSlice.reducer;
