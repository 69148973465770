import React from "react";

const ProgressCircle = ({
    percentage,
    height = 120,
    width = 120,
    color = "#4caf50"
}) => {
    const radius = Math.min(height, width) / 2 - 10;
    const strokeWidth = 4;
    const normalizedRadius = radius - strokeWidth / 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (
        <svg width={width} height={height}>
            <circle
                stroke="#D8DEE4"
                fill="transparent"
                strokeWidth={strokeWidth}
                r={normalizedRadius}
                cx={width / 2}
                cy={height / 2}
            />
            <circle
                stroke={color}
                fill="transparent"
                strokeWidth={strokeWidth}
                r={normalizedRadius}
                cx={width / 2}
                cy={height / 2}
                strokeDasharray={circumference + " " + circumference}
                strokeDashoffset={strokeDashoffset}
                style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
            />
        </svg>
    );
};

export default ProgressCircle;
