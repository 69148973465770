import React, { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { parsePhoneNumber } from "libphonenumber-js";
import "./premiumReport.scss";
import { renderValue, toCamelCase } from "../../utils/commonUtils";
import { UnlockFullReport } from "../../components/partials";
import OverViewMenu from "../../components/common/overViewMenu";
import { PhoneGps, PhoneReportMenu } from "../../components/common";
import { neighborSpooFingIcon } from "../../assets/images";

import {
  AddressHistory,
  DataLeakChecker,
  DownloadSection,
  EmailAddress,
  GeneralPhoneInfo,
  Monitoring,
  NameAndAncestry,
  OtherInformation,
  OtherPhoneNumber,
  PhoneApproximateLocation,
  PhoneOwnerInformation,
  PhoneReputation,
  PossibleEducation,
  PossiblePhotos,
  Relationship,
  SearchVolume,
  SocialProfile,
  WorkHistory,
} from "../../components/partials/sections";
import Loader from "../../components/common/loader/loader";
import SectionAction from "../../components/partials/sectionAction/sectionAction";
import { ProcessModalContext } from "../../context/processModalProvider";
import UnlockReportBackgroundProcess from "../../components/partials/Modals/UnlockReportBackgroundProcess/UnlockReportBackgroundProcess";
import ReportAccessModal from "../../components/partials/Modals/ReportAccessModal/ReportAccessModal";
import PhoneNumberAttributeDetails from "../../components/partials/Modals/phoneNumberAttributeDetails/phoneNumberAttributeDetails";

const PremiumPageComponent = (props) => {
  const {
    phoneReportDetails,
    senderNumber,
    setOpenLocateModal,
    locationListData,
    handleViewReport,
    onInfoSubmit,
    setInfoModal,
    infoModal,
    setIsLoading,
  } = props;

  const { nuisance_call_scan_detection, potential_neighbors_spoofing, status } =
    phoneReportDetails?.phoneReport || {};
  const [formateNumber, setFormateNumber] = useState(0);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    if (senderNumber) {
      let fullNumber = "+" + senderNumber.replace("+", "");
      if (fullNumber.length > 8) {
        const phoneNumber = parsePhoneNumber(fullNumber);
        if (phoneNumber) {
          setFormateNumber(phoneNumber.formatInternational());
        }
      }
    }
  }, [senderNumber]);

  const checkIsLocked = (sectionName) => {
    return phoneReportDetails?.locked.includes(sectionName);
  };
  const {
    processOpenModalId,
    closeProcessModal,
    successModalId,
    closeSuccessModal,
  } = useContext(ProcessModalContext);

  return (
    <>
      {processOpenModalId === phoneReportDetails?.phoneReport._id && (
        <UnlockReportBackgroundProcess
          isOpen={true}
          onClose={closeProcessModal}
          formateNumber={formateNumber}
        />
      )}
      {successModalId === phoneReportDetails?.phoneReport._id && (
        <ReportAccessModal
          isOpen={true}
          onClose={closeSuccessModal}
          handleViewReport={handleViewReport}
        />
      )}
      {infoModal && (
        <PhoneNumberAttributeDetails
          isOpen={infoModal}
          onClose={() => {
            setInfoModal(false);
          }}
          handlePayment={onInfoSubmit}
          setIsLoading={setIsLoading}
        />
      )}
      {pdfLoading && <Loader />}
      <div className="main_root">
        <PhoneReportMenu />
        <div className="premium_sec_wrap">
          <div className="main_container">
            <div className="premium_box_wrap">
              <OverViewMenu number={formateNumber} />
              <div className="premium-section-width">
                <PhoneGps
                  number={formateNumber}
                  setOpenLocateModal={setOpenLocateModal}
                  phoneReportDetails={phoneReportDetails}
                  locationListData={locationListData}
                />
                <GeneralPhoneInfo reportDetail={phoneReportDetails} />

                <PhoneReputation
                  reportDetail={phoneReportDetails}
                  number={formateNumber}
                />
                {status !== "unlocked" && <UnlockFullReport />}

                <PhoneOwnerInformation
                  isLocked={checkIsLocked("phone_owner_info")}
                  reportDetail={phoneReportDetails}
                  sectionName={"phone_owner_info"}
                  number={formateNumber}
                />
                <EmailAddress
                  number={formateNumber}
                  isLocked={checkIsLocked("emails")}
                  reportDetail={phoneReportDetails}
                  sectionName={"emails"}
                />

                <OtherPhoneNumber
                  isLocked={checkIsLocked("other_phone_numbers")}
                  reportDetail={phoneReportDetails}
                  sectionName={"other_phone_numbers"}
                />
                <PhoneApproximateLocation
                  isLocked={checkIsLocked("phone_approximate_location")}
                  reportDetail={phoneReportDetails}
                  sectionName={"phone_approximate_location"}
                />

                <PossiblePhotos
                  number={formateNumber}
                  isLocked={checkIsLocked("possible_photos")}
                  reportDetail={phoneReportDetails}
                  sectionName={"possible_photos"}
                />

                <SocialProfile
                  isLocked={checkIsLocked("social_media_profiles")}
                  reportDetail={phoneReportDetails}
                  sectionName={"social_media_profiles"}
                />

                <WorkHistory
                  number={formateNumber}
                  isLocked={checkIsLocked("possible_jobs")}
                  reportDetail={phoneReportDetails}
                  sectionName={"possible_jobs"}
                />

                <PossibleEducation
                  isLocked={checkIsLocked("possible_educations")}
                  reportDetail={phoneReportDetails}
                  sectionName={"possible_educations"}
                />

                <AddressHistory
                  isLocked={checkIsLocked("address_history")}
                  reportDetail={phoneReportDetails}
                  sectionName={"address_history"}
                />

                <Relationship
                  number={formateNumber}
                  isLocked={checkIsLocked("possible_associates")}
                  reportDetail={phoneReportDetails}
                  sectionName={"possible_associates"}
                />

                <NameAndAncestry
                  isLocked={checkIsLocked("name_and_ancestry")}
                  reportDetail={phoneReportDetails}
                  sectionName={"name_and_ancestry"}
                />

                <DataLeakChecker
                  isLocked={checkIsLocked("data_leak_checker")}
                  reportDetail={phoneReportDetails}
                  sectionName={"data_leak_checker"}
                />
                {/* <OtherInformation
                  isLocked={checkIsLocked("other_information")}
                  reportDetail={phoneReportDetails}
                  sectionName={"other_information"}
                /> */}

                <div
                  className="ds--complaints-sec light-bg-wrap nuisance_call_detection_section"
                  id={toCamelCase("Nuisance Call Detection")}
                >
                  <div className="ds--unlock__title left-content main_title toggle_area">
                    <div className="unlock-information-sec">
                      <h3>
                        {t("PR_NUISANCE_CALL_SCAN")}:{" "}
                        {renderValue(
                          nuisance_call_scan_detection?.data?.is_spammer
                        )}
                      </h3>
                    </div>
                    <SectionAction />
                  </div>
                  <div className="ds--alert-box">
                    <div className="unlock-information-sec">
                      <p>{t("PR_NUISANCE_DESCRIPTION")}</p>
                    </div>
                  </div>
                </div>

                <div
                  className="ds--unlock-info-sec light-bg-wrap"
                  id={toCamelCase("Neighbour Spoofing")}
                >
                  <div className="unlock-information-sec text-center">
                    <div className="toggle_area">
                      <SectionAction />
                    </div>
                    <img src={neighborSpooFingIcon} alt="" />
                    <h3>
                      {t("PR_POTENTIAL_NEIGHBOUR_SPOOF")}:{" "}
                      {/* There is mistake in String it self */}
                      {renderValue(
                        potential_neighbors_spoofing?.data?.is_risky
                      )}
                    </h3>
                    <p>
                      {potential_neighbors_spoofing?.data?.is_risky &&
                        t("PR_NEIGHBOR_SUB_DESCRIPTION")}
                    </p>
                    <p>
                      {potential_neighbors_spoofing?.data?.is_risky
                        ? t("PR_NEIGHBOR_DESCRIPTION_2")
                        : t("PR_NEIGHBOR_DESCRIPTION")}
                    </p>
                  </div>
                </div>

                <SearchVolume reportDetail={phoneReportDetails} />
                <Monitoring />
                <DownloadSection
                  reportDetail={phoneReportDetails}
                  setPdfLoading={setPdfLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PremiumPageComponent;
