import React from "react";
import "./contactUs.scss";
import { contactUsImage } from "../../assets/images";
import { t } from "i18next";

const ContactUsComponent = () => {
  return (
    <div className="main_root">
      <div className="main_container">
        <div className="contact_wrap">
          <div className="contact__inner_wrap">
            <div className="contact_sec_content">
              <h2>{t("CONTACT_US_TITLE")}</h2>
              <span>
                {t("PR_CONTACT_EMAIL")}{" "}
                <a href="mailto:support@reversly.com">support@reversly.com</a>
              </span>
              <p>
              {t("CONTACT_US_TEXT")}
              </p>
            </div>
            <div className="contact_sec_img">
              <img src={contactUsImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsComponent;
