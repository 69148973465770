import React, { useEffect, useState } from "react";
import "./header.scss";
import MainLogo from "../../assets/header/Reversly-white-logo.svg";
import NewReverslyLogo from "../../assets/newhome/NewReverslyLogo.svg";

import { BsFillPersonFill } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import { Dropdown, Modal, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { languages, renderRTLClass } from "../../utils/commonUtils";
import ReactCountryFlag from "react-country-flag";
import useScrollToTop from "../customHook/useScrollToTop";

const HeaderComponent = (props) => {
  const { page, logoLoad } = props;
  useScrollToTop();
  const cookie = new Cookies();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [lang, setLang] = useState();
  const [showLogo, setShowLogo] = useState("gb");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState();
  useEffect(() => {
    const cookieLang = cookie.get("lang");
    const browserLanguage = navigator.language || navigator.userLanguage;
    setLang(cookieLang ? cookieLang : browserLanguage);
    // eslint-disable-next-line
  }, [cookie, window.location]);

  useEffect(() => {}, [lang]);
  const items = [
    {
      label: <Link to={`/${lang}/faq`}>{t("MENU_FAQ")}</Link>,
      key: "0",
    },
    {
      label: <Link to={`/${lang}/pricing`}>{t("FOOTER_PRICING")}</Link>,
      key: "1",
    },
    {
      label: <Link to={`/${lang}/contact`}>{t("MENU_CONTACT")}</Link>,
      key: "3",
    },
    {
      label: <Link to={`/${lang}/unsubscribe`}>{t("MENU_UNSUBSCRIBE")}</Link>,
      key: "4",
    },
    {
      label: (
        <div className="header-login-res-btn">
          <Link to={`/${lang}/login`}>
            <button>
              <BsFillPersonFill />
              {t("LOGIN")}
            </button>
          </Link>
        </div>
      ),
      key: "4",
    },
  ];
  useEffect(() => {
    const find = languages.find((item) => {
      return item.code === window.location.pathname.split("/")[1];
    });
    if (find) {
      cookie.set("langlogo", find.flagCode);
      setUpdatedFlag(find.flagCode);
    }
    // eslint-disable-next-line
  }, [lang, window.location.pathname, cookie]);

  //  disable language for temp
  const showModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    document.body.classList.toggle("no-scroll", isModalOpen);
    return () => document.body.classList.remove("no-scroll");
  }, [isModalOpen]);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    i18n.changeLanguage(lang);
    // eslint-disable-next-line
  }, []);

  //  disable language for temp
  const handleChangeLanguage = (lang, logo) => {
    i18n.changeLanguage(lang);
    if (window.location.pathname.split("/")[2]) {
      navigate(`/${lang}/${window.location.pathname.split("/")[2]}`);
    } else {
      navigate(`/${lang}`);
    }
    setIsModalOpen(false);
    setLang(lang);
    cookie.set("lang", lang, {
      path: "/",
      sameSite: true,
    });
    cookie.set("langlogo", logo, {
      path: "/",
      sameSite: true,
    });
  };
  useEffect(() => {
    setShowLogo(updatedFlag);
    // eslint-disable-next-line
  }, [cookie, window.location.pathname]);
  return (
    <div className="NewHomeHeader bg-white">
      <div className="NewContainer">
        <div className="Header_wrapper">
          <div className="HeaderLogo__wrapper">
            <Link to={`/${lang}/`}>
              <img
                src={NewReverslyLogo}
                className="HeaderLogo"
                alt="Main Logo"
              />
            </Link>
          </div>
          <div className="HeaderMenuList">
            <ul>
              <li
                className={
                  page === "track" || page === "trace" ? "track-menu" : ""
                }
              >
                <Link to={`/${lang}/faq`}>{t("MENU_FAQ")}</Link>
              </li>
              <li
                className={
                  page === "track" || page === "trace" ? "track-menu" : ""
                }
              >
                <Link to={`/${lang}/pricing`}>{t("FOOTER_PRICING")}</Link>
              </li>

              <li
                className={
                  page === "track" || page === "trace" ? "track-menu" : ""
                }
              >
                <Link to={`/${lang}/contact`}>{t("MENU_CONTACT")}</Link>
              </li>
              <li
                className={
                  page === "track" || page === "trace" ? "track-menu" : ""
                }
              >
                <Link to={`/${lang}/unsubscribe`}>{t("MENU_UNSUBSCRIBE")}</Link>
              </li>
            </ul>
          </div>
           {/* disable language for temp  */}
           <div className="HeaderFuncList">
            <div className="header-lang-drop">
              <li>
                {showLogo && (
                  <div onClick={showModal}>
                    <ReactCountryFlag
                      countryCode={showLogo}
                      svg
                      style={{
                        width:
                          page === "track" || page === "trace"
                            ? "24px"
                            : "27px",
                        height:
                          page === "track" || page === "trace"
                            ? "24px"
                            : "27px",
                      }}
                    />
                  </div>
                )}
              </li>
            </div>
            <div className="header-login-btn">
              <Link to={`/${lang}/login`}>
                <div className="HeaderLogo__Button">
                  <a href="#"> {t("LOGIN")}</a>
                </div>
              </Link>
            </div>
            <div className="responsive-header-btn">
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="bottomRight"
                overlayClassName="newMenuDropDown"
              >
                <span onClick={(e) => e.preventDefault()}>
                  <Space>
                    <HiMenu />
                  </Space>
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="modal-container">
          <div className="modal-title">
            <p>{t("LANGUAGE")}</p>
          </div>
          <div className="modal-languages">
            <div className="df-modal-language">
              {languages.map((item, index) => (
                <div
                  className={`lang ${lang === item.code && "active"}`}
                  onClick={() => handleChangeLanguage(item.code, item.flagCode)}
                  key={index}
                >
                  <div className="flag">
                    <ReactCountryFlag
                      countryCode={item.flagCode}
                      svg
                      style={{
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </div>
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HeaderComponent;
