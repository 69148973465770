import React from "react";
import { t } from "i18next";
import { alertIco } from "../../../assets/images";
import { renderValue } from "../../../utils/commonUtils";
import SectionAction from "../sectionAction/sectionAction";

const SearchVolume = ({ reportDetail }) => {
  const { data } = reportDetail?.phoneReport?.search_volume || {};
  return (
    <div
      className="Searchvolume ds--unlock-info-sec light-bg-wrap"
      id="searchVolume"
    >
      <div className="toggle_area">
        <SectionAction />
      </div>
      <div className="ds--alert-box">
        <div className="alert--content-area">
          <div className="alert--img">
            <img src={alertIco} alt="" />
          </div>
        </div>
        <div className="unlock-information-sec">
          <h3>
            {t("PR_SEARCH_VOLUME")}: {renderValue(data.user_activity)}
          </h3>
          <p>{t("PR_SEARCH_VOLUME_DESCRIPTION_2")}</p>
          <div className="ds-alert-search">
            <p>{t("PR_SEARCH_VOLUME_TEXT_1")}</p>
            <p>
              <span>
                {renderValue(data?.number_search_count)}{" "}
                {data?.number_search_count > 1
                  ? t("PR_TIMES")
                  : data?.number_search_count <= 1 && t("PR_TIME")}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchVolume;
