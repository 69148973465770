import { API_KEY } from "../environment";
import axios from "axios";
import SessionExpiredModal from "../components/sessionExpired/sessionExpiredModal";
import { Cookies } from "react-cookie";
import { Modal } from "antd";

const AxiosInstance = axios.create();

let merchantNotExist = false;
let isModalOpen = false;
const cookies = new Cookies();

AxiosInstance.interceptors.request.use(
  (config) => {
    const token = cookies.get("token");
    config.baseURL = API_KEY;
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      config.headers["x-site"] = "https://reversly.com";
    }
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["x-language"] = cookies.get("lang") || "en";
    return config;
  },
  (error) => Promise.reject(error)
);

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && !isModalOpen) {
      isModalOpen = true;
      Modal.error({
        content: <SessionExpiredModal merchantNotExist={merchantNotExist} />,
        centered: true,
        cancelButtonProps: { style: { display: "none" } },
        okButtonProps: { style: { display: "none" } },
        closable: false,
        className: "session_expired_modal",
        onOk: () => {
          isModalOpen = false;
        },
        onCancel: () => {
          isModalOpen = false;
        },
      });
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
