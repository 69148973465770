import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";

export const sendPdfEmail = createAsyncThunk(
  "sendPdfEmail",
  async (phoneReportId, {rejectWithValue}) => {
    try {
      const response = await AxiosInstance.post(
        `/phone_report/email_phone_report_pdf`,
        {
          phone_report_id: phoneReportId
        }
      );
      return {data: response.data, status: response.status};
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          message: error.response.data.message,
          status: error.response.status
        });
      } else {
        console.log("else");
        throw error;
      }
    }
  }
);

const sendPdfEmailSlice = createSlice({
  name: "sendPdfEmail",
  initialState: {
    isLoading: false,
    sendPdfEmail: null,
    isError: false,
    errorMessage: "",
    statusCode: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendPdfEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendPdfEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sendPdfEmail = action.payload.data;
        state.statusCode = action.payload.status;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(sendPdfEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload?.message || action.error.message;
        state.statusCode = action.payload?.status || null;
      });
  }
});

export default sendPdfEmailSlice.reducer;
