import React from 'react'
import NoInformationFoundComponent from './noInformationFound'
import { connect } from 'react-redux';

const NoInformationFound = ({phoneReportDetails}) => {
  return (
    <NoInformationFoundComponent phoneReportDetails={phoneReportDetails?.getPhoneReportDetailsData?.data}/>
  )
}
const mapStateToProps = (state) => {
  return {
    phoneReportDetails: state.getPhoneReportDetails,
  };
};
export default connect(mapStateToProps, null)(NoInformationFound);