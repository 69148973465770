import React from "react";
import "./previewPopup.scss";
import { newLocationPreviewPopupImg, previewPopupImg } from "../../assets/images";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { sendLocation } from "../../redux/slice";
import { createFirstReport } from "../../redux/slice/createFirstReportSlice";
import ModalComponent from "../../components/common/Modal/modal";

const PreviewPopup = (props) => {
  const {
    className,
    isOpen,
    handleSendMessage,
    message,
    formatedNumber,
    popNumberError,
    setLocatingModalOpen,
    setIsOpen,
  } = props;

  const { t } = useTranslation();
  return (
    <ModalComponent isOpen={isOpen} closable={false} className={className}>
      <div className="location_popup">
        <div className="location_popup-container">
          <div className="location-info">
            <div className="location-box">
              <h2
                dangerouslySetInnerHTML={{
                  __html: t("PR_PREVIEW_POPUP_TITLE"),
                }}
              ></h2>
              <h2>
                <p>{formatedNumber}</p>
              </h2>
              <p>{t("PR_PREVIEW_POPUP_DESCRIPTION")}</p>
              {popNumberError && (
                <p className="error-message">{popNumberError}</p>
              )}{" "}
              <div className="location_image_container">
                <img src={newLocationPreviewPopupImg} alt="" className="location_image" />
                <div className="text-overflow">
                  <p className="message_overlay">{message}</p>
                </div>
              </div>
              <div className="location_button">
                <p>
                  <button onClick={handleSendMessage} className="defult_btn">
                    {t("PR_SEND")}
                  </button>
                </p>
                <p>
                  <button
                    onClick={() => {
                      setLocatingModalOpen(true);
                      setIsOpen(false);
                    }}
                    className="defult_btn btn_border"
                  >
                    {t("PR_EDIT")}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    callCreateFirstReport: state.callCreateFirstReport,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callsendLocation: (data) => dispatch(sendLocation(data)),
    callCreateFirstReport: (data) => dispatch(createFirstReport(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPopup);
