import React from "react";
import { t } from "i18next";
import {
  premiumToggleBar,
  accordionIcon,
  possibleAssociatesImg,
  nodataIcon,
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";
import SectionAction from "../sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";

const Relationship = ({ isLocked, sectionName, reportDetail, number }) => {
  const { possible_associates } = reportDetail?.phoneReport || {};
  const { data, status } = possible_associates || {};
  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const isData = phone_owner_info?.status === "found";

  return isLocked ? (
    <UnlockReport
      title={t("PR_PREMIUM_SECTION_TITLE_4")}
      image={possibleAssociatesImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_ASSOCIATE_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"possible_associates"}
      id={"Relationships & Associates"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound
      sectionName={sectionName}
      id={toCamelCase("Relationships & Associates")}
    />
  ) : (
    <div
      className="ds--unlock-jobs white-bg-wrap"
      id={toCamelCase("Relationships & Associates")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PREMIUM_SECTION_TITLE_4")}</h2>
        <SectionAction />
      </div>
      {status === "found" && data.length > 0 ? (
        <>
          <div className="ds--accordion-collapse">
            <Collapse
              items={[
                {
                  key: "1",
                  label: t("PR_LEARN_MORE"),
                  children: (
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          isData && phone_owner_info?.data?.name
                            ? t("PR_RELATIONSHIP_ASSOCIATE_TITLE_2", {
                                number: data ? data.length : "0",
                                phone_owner_full_name: isData
                                  ? renderValue(phone_owner_info?.data?.name)
                                  : "USER",
                              })
                            : t("PR_POSSIBLE_PHOTOS_LEARN_MORE_1", {
                                number: data ? data.length : "0",
                                phone_number: `<p class="mobile_number">${
                                  isData
                                    ? reportDetail?.phoneReport
                                        .phone_number_prefix +
                                      reportDetail?.phoneReport.phone_number
                                    : "USER"
                                }</p>`,
                                phone_owner_full_name: isData
                                  ? renderValue(phone_owner_info?.data?.name)
                                  : "USER",
                              }),
                      }}
                    ></p>
                  ),
                },
              ]}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <img
                  src={isActive ? accordionIcon : accordionIcon}
                  width="2em"
                  height="2em"
                  alt=""
                />
              )}
            />
          </div>
          <div className="ds--job-wrapper">
            {data.map((item, index) => {
              return (
                <div className="ds--job-box email--underline" key={index}>
                  {item.name && <h3>{item.name}</h3>}
                  <p>
                    <b>{t("PR_RELATIONSHIP")}</b>
                    {renderValue(item.relationship)}
                  </p>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <p className="data_notfound">
          <img src={nodataIcon} alt="" /> {t("PR_RECORD_NOT_FOUND")}
        </p>
      )}
    </div>
  );
};

export default Relationship;
