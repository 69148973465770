import React from "react";
import "./phoneReputationScoreDetails.scss";
import ModalComponent from "../../../common/Modal/modal";
import { t } from "i18next";

const PhoneReputationScoreDetails = ({
  isOpen,
  onClose,
  closable,
  className,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={closable}
      className={className}
    >
      <div className="mga__attribute_popup">
        <div className="attribute_info_popup">
          <div className="information_popup_text">
            <h2>{t("PR_PHONE_REPUTATION_TITLE")}</h2>
          </div>
          <div className="attribute_info_content">
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PREMIUM_REPORT_TEXT_1")}</h3>
              <p>{t("PR_PREMIUM_REPORT_DESC_1")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PREMIUM_REPORT_TEXT_2")}</h3>
              <p>{t("PR_PREMIUM_REPORT_DESC_2")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PREMIUM_REPORT_TEXT_3")}</h3>
              <p>{t("PR_PREMIUM_REPORT_DESC_3")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PREMIUM_REPORT_TEXT_4")}</h3>
              <p>{t("PR_PREMIUM_REPORT_DESC_4")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PREMIUM_REPORT_TEXT_5")}</h3>
              <p>{t("PR_PREMIUM_REPORT_DESC_5")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PREMIUM_REPORT_TEXT_6")}</h3>
              <p>{t("PR_PREMIUM_REPORT_DESC_6")}</p>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default PhoneReputationScoreDetails;
