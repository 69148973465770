import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import williamDavisImg from "../../assets/newhome/williamDavisImg.svg";
import robertJohnsonImg from "../../assets/newhome/robertJohnsonImg.svg";
import richardThompsonImg from "../../assets/newhome/richardThompsonImg.svg";
import miaTaylorImg from "../../assets/newhome/miaTaylorImg.svg";
import sophiaHernandezImg from "../../assets/newhome/sophiaHernandezImg.svg";
import michaelJonesImg from "../../assets/newhome/michaelJones.svg";
import "./newSlickSlider.scss";

const NewSlickSlider = () => {
  const { t } = useTranslation();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: true,
    swipeToSlide: true,
    cssEase: 'ease-in-out',
    arrows: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: t("PR_REVIEWER_NAME_1"),
      text: `“${t("PR_REVIEWER_REVIEW_1")}”`,
      image: williamDavisImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_2"),
      text: `“${t("PR_REVIEWER_REVIEW_2")}”`,
      image: robertJohnsonImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_3"),
      text: `“${t("PR_REVIEWER_REVIEW_3")}”`,
      image: richardThompsonImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_4"),
      text: `“${t("PR_REVIEWER_REVIEW_4")}”`,
      image: miaTaylorImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_5"),
      text: `“${t("PR_REVIEWER_REVIEW_6")}”`,
      image: sophiaHernandezImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_6"),
      text: `“${t("PR_REVIEWER_REVIEW_7")}”`,
      image: michaelJonesImg,
      rating: 5,
    },
  ];

  return (
    <Slider {...settings}>
      {testimonials.map((testimonial, index) => (
        <div key={index} className="testimonial-card">
          <div className="testimonial-header">
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className="testimonial-image"
            />
            <div className="testimonial-rating">
              {Array(testimonial.rating)
                .fill()
                .map((_, i) => (
                  <span key={i}>⭐</span>
                ))}
            </div>
            <h3>{testimonial.name}</h3>
          </div>
          <div className="testimonial-body">
            <p>{testimonial.text}</p>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default NewSlickSlider;
