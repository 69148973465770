import React, { useEffect, useState } from "react";
import "./phoneReportDashboard.scss";
import { MdOutlineSearch } from "react-icons/md";
import { Cookies } from "react-cookie";
import { parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import ReportList from "../../components/partials/reportList/reportList";
import {
  handleSetNumber,
  handleSetPhoneNumber,
} from "../../../utils/commonUtils";
import { useAppContextInput } from "../../../App";

const PhoneReportDashboardComponent = ({
  countryShortName,
  senderNumber,
  number,
  numberError,
  handleLocateNewNumber,
  locationListData,
  loading,
  setFormatedNumber,
  setCountryCodeinput,
  setCountryShortName,
  numberValidate,
  setNumberValidate,
}) => {
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const { t } = useTranslation();
  const { inputRef } = useAppContextInput();

  const [dialCode, setDialCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [finalFormattedNumber, setFinalFormattedNumber] = useState();
  useEffect(() => {
    if (senderNumber) {
      let fullNumber = "+" + senderNumber.replace("+", "");
      if (fullNumber.length > 8) {
        const phoneNumber = parsePhoneNumber(fullNumber);
        if (phoneNumber) {
          setFormatedNumber(phoneNumber.formatInternational());
        }
      }
    }
  }, [senderNumber]);
  const handleSendMessage = () => {
    if (finalFormattedNumber) {
      handleLocateNewNumber(dialCode, phoneNumber, finalFormattedNumber);
    } else {
      setNumberValidate(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="main_root">
          <div className="ds--dashboard-wrap">
            <div className="main_container">
              <div className="mt-50 dashboard_search__report white-bg-wrap">
                <div className="dashboard__title">
                  <h2>{t("PR_DASHBOARD_TEXT_1")}</h2>
                </div>
                <div className="location_search">
                  <form>
                    <div className="location_form_field">
                      <PhoneInput
                        country={countryShortName.toLowerCase() || "ca"}
                        onChange={(value, country) => {
                          handleSetNumber(
                            value,
                            country,
                            setDialCode,
                            setNumberValidate,
                            setCountryShortName,
                            setPhoneNumber,
                            setFinalFormattedNumber,
                            phoneNumber
                          );
                        }}
                        inputProps={{
                          className: "form-control form_input",
                          disabled: true,
                        }}
                        placeholder={t("BANNER_INPUT")}
                        onEnterKeyPress={handleLocateNewNumber}
                        countryCodeEditable={false}
                        autoFormat={true}
                        enableSearch={true}
                        searchStyle={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "7px",
                          margin: "0px",
                          padding: "0px; !important",
                        }}
                        disableSearchIcon={true}
                        searchPlaceholder={t("SEARCH")}
                        searchNotFound={t("NO_MATCHED")}
                      />
                      <Input
                        ref={inputRef}
                        className={`input-form form-control`}
                        onChange={(event) => {
                          setNumberValidate("");
                          handleSetPhoneNumber(
                            event,
                            setPhoneNumber,
                            setNumberValidate,
                            setCountryShortName,
                            countryShortName,
                            setDialCode,
                            setFinalFormattedNumber,
                            inputRef
                          );
                        }}
                        placeholder={t("BANNER_INPUT")}
                        maxLength="20"
                        type="text"
                        inputMode="tel"
                        value={phoneNumber}
                        id="phone_input"
                        onPressEnter={handleSendMessage}
                      />
                      <button
                        type="button"
                        className="click-here m-left"
                        id="btnSubmit"
                        onClick={handleSendMessage}
                      >
                        <MdOutlineSearch
                          style={{
                            fontSize: 26,
                            color: "#1677ff",
                          }}
                          country={countryShortName?.toLowerCase()}
                          value={number}
                          placeholder={t("BANNER_INPUT")}
                          onEnterKeyPress={handleLocateNewNumber}
                          countryCodeEditable={false}
                          autoFormat={false}
                        />
                        <span>Search</span>
                      </button>
                    </div>
                    {numberError && (
                      <p
                        style={{
                          marginBottom: "1",
                          marginTop: "0",
                          color: "red",
                        }}
                      >
                        {numberError}
                      </p>
                    )}
                    {numberValidate && (
                      <p
                        style={{
                          marginBottom: "1",
                          marginTop: "0",
                          color: "red",
                        }}
                      >
                        {numberValidate}
                      </p>
                    )}
                  </form>
                </div>
              </div>
              {locationListData?.data?.length > 0 && (
                <div className="wpb_report_wrapper white-bg-wrap">
                  <div className="dashboard__title">
                    <h2>{t("PR_DASHBOARD_TITLE")}</h2>
                  </div>
                  {locationListData.data.map((item, index) => (
                    <ReportList key={index} item={item} lang={lang} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default PhoneReportDashboardComponent;
