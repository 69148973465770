import React, { useEffect } from "react";
import "./termConditions.scss";
import { scrollToTop } from "../../utils/commonUtils";
import { t } from "i18next";

const TermConditions = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="main_root">
      <div className="Policy-header-section">
        <div className="main_container">
          <h1 className="Policy-title">{t("TC_TITLE")}</h1>
        </div>
      </div>
      <div className="wpb_info_wrapper">
        <div className="main_container">
          <div className="cms__poolicy_wrapper">
            {!["en"].includes(window.location.pathname.split("/")[1]) && (
              <strong class="policy_desc mb-2">
                {t("PP_TERMS_PRIVACY_NOTE")}
              </strong>
            )}
            <p class="policy_desc">{t("PP_TERMS_FOLLOW_ALL_USER")}</p>
            <p class="policy_desc mb-2">{t("PP_TERMS_UPDATE_DATE")}</p>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">1. {t("PR_TOS_TITLE")}</h2>
              <p class="policy_desc mb-1">{t("PR_TOS_INTRO")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">2. {t("PR_CHANGES_TOS")}</h2>
              <p class="policy_desc mb-1">{t("PR_CHANGES_TOS_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">3. {t("PR_ELIGIBILITY")}</h2>
              <p class="policy_desc mb-1">{t("PR_ELIGIBILITY_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">4. {t("PR_LIMITATION_USE")}</h2>
              <p class="policy_desc mb-1">
                {t("PR_LIMITATION_USE_DESC_1")} (1) {t("PR_INFRINGE_RIGHTS")},
                (2) {t("PR_UNLAWFUL_CONTENT")}, (3) {t("PR_ENCOURAGE_ILLEGAL")},
                (4)
                {t("PR_CIVIL_LIABILITY")} (5) {t("PR_LAW_VIOLATION")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">5. {t("PR_ACCEPTABLE_USE")}</h2>
              <p class="policy_desc mb-1">{t("PR_YOU_MUST_NOT")}:</p>
              <ul class="policy__item">
                <li>{t("PR_CAUSE_DAMAGE")}.</li>
                <li>{t("PR_USE_MALWARE")}.</li>
                <li>{t("PR_DATA_COLLECTION")}.</li>
                <li>{t("PR_REPUBLISH_MATERIAL")}.</li>
                <li>{t("PR_SELL_MATERIAL")}.</li>
                <li>{t("PR_COPY_MATERIAL")}.</li>
                <li>{t("PR_DEVELOP_SUPPORT")}.</li>
                <li>{t("PR_MODIFY_COPYRIGHT")}.</li>
                <li>{t("PR_VIOLATE_SECURITY")}.</li>
              </ul>
              <p class="policy_desc mb-1">{t("PR_MONITOR_WEBSITE")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                6. {t("PR_USER_RESPONSIBILITY")}
              </h2>
              <p class="policy_desc mb-1">
                {t("PR_USER_RESPONSIBILITY_DESC")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                7. {t("PR_PROPRIETARY_MATERIALS")}
              </h2>
              <p class="policy_desc mb-1">
                {t("PR_PROPRIETARY_MATERIALS_DESC")}.
              </p>
              <p class="policy_desc mb-1">{t("PR_COPYRIGHT_PROTECTION")}.</p>
              <p class="policy_desc mb-1">{t("PR_AGREE_OR_NOT")}.</p>
              <p class="policy_desc mb-1">{t("PR_THIRD_PARTY_CONTENT")}.</p>
              <p class="policy_desc mb-1">{t("PR_TRADEMARKS")}.</p>
              <p class="policy_desc mb-1">{t("PR_TRADEMARKS_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">8. {t("PR_CHANGES_WEBSITE")}</h2>
              <p class="policy_desc mb-1">{t("PR_CHANGES_WEBSITE_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                9. {t("PR_DISCLAIMER_WARRANTY")}
              </h2>
              <p class="policy_desc mb-1 text__uppercase">
                {t("PR_DISCLAIMER_WARRANTY_DESC_1")}: (I){" "}
                {t("PR_DISCLAIMER_WARRANTY_DESC_2")} (ii){" "}
                {t("PR_DISCLAIMER_WARRANTY_DESC_3")}.
              </p>
              <p class="policy_desc mb-1 text__uppercase">
                {t("PR_DISCLAIMER_WARRANTY_DESC_4")} (1){" "}
                {t("PR_DISCLAIMER_WARRANTY_DESC_5")} (2){" "}
                {t("PR_DISCLAIMER_WARRANTY_DESC_6")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">10. {t("PR_NO_LIABILITY")}</h2>
              <p class="policy_desc mb-1 text__uppercase">
                {t("PR_NO_LIABILITY_DESC_1")} (I) {t("PR_NO_LIABILITY_DESC_2")}{" "}
                (ii) {t("PR_NO_LIABILITY_DESC_3")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                11. {t("PR_INTERNATIONAL_USE")}
              </h2>
              <p class="policy_desc mb-1">{t("PR_INTERNATIONAL_USE_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">12. {t("PR_NO_SUPPORT")}</h2>
              <p class="policy_desc mb-1">{t("PR_NO_SUPPORT_DESC")}</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">13. {t("PR_INDEMNIFICATION")}</h2>
              <p class="policy_desc mb-1">{t("PR_INDEMNIFICATION_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">14. {t("PR_TERMINATION")}</h2>
              <p class="policy_desc mb-1">{t("PR_TERMINATION_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                15. {t("PR_MEMBERSHIP_OFFERS")}
              </h2>
              <p class="policy_desc mb-1">
                <b>{t("PR_SUBSCRIPTION_PLANS")}</b>
              </p>
              <p class="policy_desc mb-1">{t("PR_SUBSCRIPTION_PLANS_DESC")}.</p>
              <ul class="policy__item">
                <li>{t("PR_SUBSCRIPTION_RENEWAL")}</li>
                <li>{t("PR_MULTIPLE_ACCOUNTS")}.</li>
              </ul>
              <p class="policy_desc mb-1">{t("PR_PAYMENT_DECLINED")}.</p>
              <p class="policy_desc mb-1">
                <b>{t("PR_NON_STANDARD_PLANS")}.</b>
              </p>
              <p class="policy_desc mb-1">
                {t("PR_NON_STANDARD_PLANS_DESC_1")}.
              </p>
              <p class="policy_desc mb-1 text__uppercase">
                {t("PR_NON_STANDARD_PLANS_DESC_2")}.
              </p>
              <p class="policy_desc mb-1">
                {t("PR_NON_STANDARD_PLANS_DESC_3")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">16. {t("PR_CANCEL_POLICY")}</h2>
              <p class="policy_desc mb-1">{t("PR_CANCEL_SUBSCRIPTION")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                17. {t("PR_DISCOUNTS_PROMOTIONS")}
              </h2>
              <p class="policy_desc mb-1">
                {t("PR_DISCOUNTS_PROMOTIONS_DESC")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                18. {t("PR_REVERSALS_CHARGEBACKS")}
              </h2>
              <p class="policy_desc mb-1">
                {t("PR_REVERSALS_CHARGEBACKS_DESC")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">19. {t("PR_PAYMENT_INFO")}</h2>
              <p class="policy_desc mb-1">{t("PR_PAYMENT_INFO_DESC_1")}</p>
              <p class="policy_desc mb-1">{t("PR_PAYMENT_INFO_DESC_2")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                20. {t("PR_THIRD_PARTY_SERVICES")}
              </h2>
              <p class="policy_desc mb-1">
                {t("PR_THIRD_PARTY_SERVICES_DESC_1")}.
              </p>
              <p class="policy_desc mb-1">
                {t("PR_THIRD_PARTY_SERVICES_DESC_2")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">21. {t("PR_EXPORT_CONTROL")}</h2>
              <p class="policy_desc mb-1">{t("PR_EXPORT_CONTROL_DESC")}</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                22. {t("PR_ELECTRONIC_DELIVERY")}
              </h2>
              <p class="policy_desc mb-1">
                {t("PR_ELECTRONIC_DELIVERY_DESC")}: (1) {t("PR_NOTICE_EMAIL")}{" "}
                (2){t("PR_NOTICE_WELCOME_SCREEN")}.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">23. {t("PR_ATTRIBUTION")}</h2>
              <p class="policy_desc mb-1">{t("PR_ATTRIBUTION_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2">
              <h2 className="policy_titleh2">24. {t("PR_MISCELLANEOUS")}</h2>
              <p class="policy_desc mb-1">
                <b>24.1 {t("PR_ENTIRE_AGREEMENT")}</b>
              </p>
              <p class="policy_desc mb-1">{t("PR_ENTIRE_AGREEMENT_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2">
              <p class="policy_desc mb-1">
                <b>24.2 {t("PR_NO_WAIVER")}</b>
              </p>
              <p class="policy_desc mb-1">{t("PR_NO_WAIVER_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2">
              <p class="policy_desc mb-1">
                <b>24.3 {t("PR_SURVIVAL")}</b>
              </p>
              <p class="policy_desc mb-1">{t("PR_SURVIVAL_DESC")}.</p>
            </div>
            <div class="web_docs_section mb-2">
              <p class="policy_desc mb-1">
                <b>24.4 {t("PR_UNENFORCEABLE_PROVISION")}</b>
              </p>
              <p class="policy_desc mb-1">
                {t("PR_UNENFORCEABLE_PROVISION_DESC")}.
              </p>
            </div>
            <div class="web_docs_section mb-2">
              <p class="policy_desc mb-1">
                <b>24.5 {t("PR_ASSIGNMENT")}</b>
              </p>
              <p class="policy_desc mb-1">{t("PR_ASSIGNMENT_DESC")}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermConditions;
