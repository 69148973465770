import { Cookies } from "react-cookie";
import {
  staticMap,
  socialSnapchatIcon,
  socialLinkedinIcon,
  dribbleIcon,
  flickrIcon,
  githubIcon,
  pinterestIcon,
  redditIcon,
  skypeIcon,
  tumblrIcon,
  twitterIcon,
  whatsappIcon,
  youtubeIcon,
  tikTokIcon,
  weChatIcon,
  telegramIcon,
  discordIcon,
  twitchIcon,
  threadsIcon,
  socialFacebookIcon,
  socialInstagramIcon,
  defaultSocialMedia,
} from "../assets/images";
import { NextArrowComponent, PrevArrowComponent } from "../components/common";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { t } from "i18next";
import moment from "moment-timezone";

export const carouselFullWidth = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 750,
  slidesToShow: 2,
  slidesToScroll: 1,
  prevArrow: <PrevArrowComponent />,
  nextArrow: <NextArrowComponent />,

  responsive: [
    {
      breakpoint: 1750,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

export const unLockFullReportData = [
  {
    translateLabel: "PR_PHONE_OWNER_INFORMATION",
    sectionName: "phone_owner_info",
    label: "Phone Owner Information",
  },
  {
    translateLabel: "PR_EMAIL_ADDRESS",
    sectionName: "emails",
    label: "Email Addresses",
  },
  {
    translateLabel: "PR_REPORT_POPUP_TEXT_6",
    sectionName: "other_phone_numbers",
    label: "Other Phone Numbers",
  },
  {
    translateLabel: "PR_PHONE_APPROXIMATE_LOCATION",
    sectionName: "phone_approximate_location",
    label: "Phone Approximate Location",
  },
  {
    translateLabel: "PR_POSSIBLE_PHOTOS",
    sectionName: "possible_photos",
    label: "Possible Photos",
  },
  {
    translateLabel: "PR_SOCIAL_PROFILES",
    sectionName: "social_media_profiles",
    label: "Social Profiles",
  },
  {
    translateLabel: "PR_PREMIUM_SECTION_TITLE_2",
    sectionName: "possible_jobs",
    label: "Work History",
  },
  {
    translateLabel: "PR_EDUCATIONAL_BACKGROUND",
    sectionName: "possible_educations",
    label: "Educational Background",
  },
  {
    translateLabel: "PR_ADDRESS_HISTORY",
    sectionName: "address_history",
    label: "Address History",
  },
  {
    translateLabel: "PR_PREMIUM_SECTION_TITLE_4",
    sectionName: "possible_associates",
    label: "Relationships & Associates",
  },
  {
    translateLabel: "PR_PREMIUM_SECTION_TEXT",
    sectionName: "name_and_ancestry",
    label: "Name & Ancestry",
  },
  {
    translateLabel: "PR_PREMIUM_SECTION_TITLE_5",
    sectionName: "data_leak_checker",
    label: "Data Leak Checker",
  },
];

function formatName(firstName) {
  const formattedFirstName = firstName.replace(/_/g, " ");
  const formattedFirstNameCapitalized =
    formattedFirstName.charAt(0).toUpperCase() + formattedFirstName.slice(1);
  return formattedFirstNameCapitalized;
}

export const validateName = (name, value) => {
  const displayName = formatName(name);
  if (!value || !value.trim()) {
    return `${displayName} is required.`;
  } else if (!/^[a-zA-Z ]+$/.test(displayName)) {
    return `${displayName} can only contain letters and spaces.`;
  } else if (displayName.length < 2 || displayName.length > 50) {
    return `${displayName} must be between 2 and 50 characters long.`;
  } else {
    return null; // No error
  }
};

export const validateStreetAddress = (name, address) => {
  const addressRegex = /^[a-zA-Z0-9\s,'-]*$/;
  if (!address || !address.trim()) {
    return "Street address is required.";
  }
  if (!addressRegex.test(address)) {
    return "Please enter a valid street address.";
  }
  return "";
};
export const validatePostalCode = (name, postalCode) => {
  if (!postalCode || !postalCode.trim()) {
    return "Postal Code is required.";
  }
  return "";
};

export const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, "") // Remove non-alphanumeric characters (optional)
    .split(" ")
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("");
};

export function formatPhoneNumber(phoneNumber, phoneNumberPrefix) {
  // Check if phoneNumber or phoneNumberPrefix is null or undefined
  if (!phoneNumber || !phoneNumberPrefix) {
    return null; // or any appropriate default value or error handling
  }

  // Remove any non-numeric characters from the phone number
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Concatenate prefix and phone number
  return phoneNumberPrefix + cleanedPhoneNumber;
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const isUserLoggedIn = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  if (token) return true;
  else return false;
};

export const formatSectionNames = (input) => {
  // Decode the URL-encoded string
  let decodedString = decodeURIComponent(input);

  // Convert the string to lowercase
  let lowerCaseString = decodedString.toLowerCase();

  // Replace spaces with underscores
  let transformedString = lowerCaseString.replace(/ /g, "_");

  return transformedString;
};

export const confirmPayment = async (stripe, clientSecret, paymentMethod) => {
  return await stripe.confirmPayment({
    clientSecret,
    confirmParams: {
      payment_method: paymentMethod,
    },
    redirect: "if_required",
  });
};

export const getSectionName = (key) => {
  switch (key) {
    case "phone_owner_info":
      return "Phone Owner Information";
    case "other_phone_numbers":
      return "Other Phone Numbers";
    case "emails":
      return "Email Addresses";
    case "social_media_profiles":
      return "Social Profiles";
    case "possible_educations":
      return "Educational Background";
    case "possible_photos":
      return "Possible Photos";
    case "possible_jobs":
      return "Work History";
    case "possible_associates":
      return "Relationships & Associates";
    case "address_history":
      return "Address History";
    case "name_and_ancestry":
      return "Name & Ancestry";
    case "phone_approximate_location":
      return "Phone Approximate Location";
    case "data_leak_checker":
      return "Data Leak Checker";
    case "other_information":
      return "Other Information";
    case "general_phone_info":
      return "General Phone Information";
    case "phone_reputation_score":
      return "Phone Reputation Score";
    case "search_volume":
      return "Search volume";
    case "potential_neighbors_spoofing":
      return "Neighbour spoofing";
    case "nuisance_call_scan_detection":
      return "Nuisance Call Detection";

    default:
      break;
  }
};
export const getTranslatedSectionName = (key) => {
  switch (key) {
    case "phone_owner_info":
      return t("PR_PHONE_OWNER_INFORMATION");
    case "other_phone_numbers":
      return t("PR_REPORT_POPUP_TEXT_6");
    case "emails":
      return t("PR_EMAIL_ADDRESS");
    case "social_media_profiles":
      return t("PR_SOCIAL_PROFILES");
    case "possible_educations":
      return t("PR_EDUCATIONAL_BACKGROUND");
    case "possible_photos":
      return t("PR_POSSIBLE_PHOTOS");
    case "possible_jobs":
      return t("PR_PREMIUM_SECTION_TITLE_2");
    case "possible_associates":
      return t("PR_PREMIUM_SECTION_TITLE_4");
    case "address_history":
      return t("PR_ADDRESS_HISTORY");
    case "name_and_ancestry":
      return t("PR_PREMIUM_SECTION_TEXT");
    case "phone_approximate_location":
      return t("PR_PHONE_APPROXIMATE_LOCATION");
    case "data_leak_checker":
      return t("PR_PREMIUM_SECTION_TITLE_5");
    case "other_information":
      return t("PR_OTHER_INFORMATION");
    case "general_phone_info":
      return t("PR_PREMIUM_SECTION_TITLE_1");
    case "phone_reputation_score":
      return t("PR_REPORT_POPUP_TEXT_4");
    case "search_volume":
      return t("PR_SEARCH_VOLUME");
    case "potential_neighbors_spoofing":
      return t("PR_NEIGHBOUR_SPOOF");
    case "nuisance_call_scan_detection":
      return t("PR_NUISANCE_CALL");

    default:
      break;
  }
};

export const mapOptions = {
  disableDefaultUI: true, // Disable default map controls
  zoomControl: false, // Disable zoom control
  streetViewControl: false, // Disable street view control
  fullscreenControl: false, // Disable fullscreen control
  mapTypeControl: false, // Disable map type control
  gestureHandling: "greedy",
  styles: [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#abcdef" }, { visibility: "on" }],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [{ color: "#f2f2f2" }],
    },
  ],
};

export const noInformationFoundDetails = [
  {
    mainTitle: "PR_PHONE_OWNER_INFORMATION",
    title: "PR_NO_INFORMATION_TITLE_5",
    phoneNumber: "(819) 674-0000",
    text: "PR_FOUND",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_OWNER_INFORMATION_DESCRIPTION",
    subDescription: "PR_OWNER_SUB_DESCRIPTION",
    mobileNumber: "(819) 674-0000.",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  {
    mainTitle: "PR_EMAIL_ADDRESS",
    title: "PR_POSSIBLE_EMAIL_TITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_EMAIL_ADDRESS_DESCRIPTION_1",
    monitorTitle: "PR_EMAIL_ADDRESS_STATUS",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  {
    mainTitle: "PR_OTHER_PHONE_NUMBERS",
    title: "PR_OTHER_PHONE_NUMBER_SUBTITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_OTHER_PHONE_NUMBER_DESCRIPTION_1",
    monitorTitle: "PR_OTHER_PHONE_NUMBER_STATUS",
    monitorStatus: "PR_UPDATE_MONTHLY",
  },
  {
    mainTitle: "PR_PHONE_APPROXIMATE_LOCATION",
    title: "PR_NO_INFORMATION_TITLE_4",
    phoneNumber: "(819) 674-0000",
    text: "PR_NOT_FOUND",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_PHONE_APPROXIMATE_LOCATION_DESCRIPTION",
    subDescription: "PR_SUB_DESCRIPTION_LOCATION",
    mobileNumber: "(819) 674-0000.",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  {
    mainTitle: "PR_POSSIBLE_PHOTOS",
    title: "PR_NO_INFORMATION_TITTLE_1",
    subTitle: "PR_UPDATE_DAILY",
    description: "PR_POSSIBLE_PHOTOS_DESCRIPTION",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  {
    mainTitle: "PR_SOCIAL_PROFILES",
    title: "PR_NO_INFORMATION_TITLE_3",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_SOCIAL_MEDIA_DESCRIPTION_2",
    monitorTitle: "PR_MONITOR_UPDATE_REPORT",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  {
    mainTitle: "PR_PREMIUM_SECTION_TITLE_2",
    title: "PR_EMPLOYMENT_HISTORY",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_HISTORY_TITLE_1",
    monitorTitle: "PR_MONITOR_UPDATE_REPORT_1",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  {
    mainTitle: "PR_PREMIUM_SECTION_TITLE_3",
    title: "PR_POSSIBLE_EDUCTION_TITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_EMAIL_DESCRIPTION",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  {
    mainTitle: "PR_ADDRESS_HISTORY",
    title: "PR_ADDRESS_HISTORY_TITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_ADDRESS_HISTORY_SUBTITLE_2",
    monitorTitle: "PR_ADDRESS_MONITOR_REPORT_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  {
    mainTitle: "PR_PREMIUM_SECTION_TITLE_4",
    title: "PR_RELATIONSHIP_TITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_RELATIONSHIP_DESCRIPTION",
    monitorTitle: "PR_MONITOR_UPDATE_REPORT_2",
    monitorStatus: "PR_MONITOR_STATUS",
  },
  // {
  //   mainTitle: "PR_EDUCATIONAL_BACKGROUND",
  //   title: "PR_POSSIBLE_EMAIL_TEXT",
  //   phoneNumber: "(819) 674-0000",
  //   text: "PR_FOUND",
  //   subTitle: "PR_UPDATE_MONTHLY",
  //   description: "PR_EMAIL_DESCRIPTION",
  //   subDescription: "PR_SUB_DESCRIPTION",
  //   mobileNumber: "(819) 674-0000.",
  //   monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
  //   monitorStatus: "PR_MONITOR_STATUS",
  // },
  {
    mainTitle: "PR_PREMIUM_SECTION_TEXT",
    title: "PR_NO_INFORMATION_TITLE_6",
    phoneNumber: "(819) 674-0000",
    text: "PR_FOUND",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_EMAIL_DESCRIPTION",
    subDescription: "PR_SUB_DESCRIPTION",
    mobileNumber: "(819) 674-0000.",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_UPDATE_MONTHLY",
  },
  {
    mainTitle: "PR_PREMIUM_SECTION_TITLE_5",
    title: "PR_NO_INFORMATION_TITLE_6",
    phoneNumber: "(819) 674-0000",
    text: "PR_FOUND",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_EMAIL_DESCRIPTION",
    subDescription: "PR_SUB_DESCRIPTION",
    mobileNumber: "(819) 674-0000.",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_UPDATE_MONTHLY",
  },
];

export const generatePdfBlob = async (elementSelector) => {
  const mainRoot = document.querySelector(`.${elementSelector}`);
  if (!mainRoot) throw new Error("Element not found");

  // SideBar For Mobile
  const menuMobileElement = mainRoot.querySelector(".menu-mobile");
  const originalDisplay = menuMobileElement?.style.display;

  // SideBar
  const sideBarElement = mainRoot.querySelector(".premium-menu-width");
  const originalPositionSideBar = sideBarElement?.style.position;

  // SectionBox
  const sectionBoxes = mainRoot.querySelectorAll(".white-bg-wrap");
  const originalSectionBoxShadows = Array.from(sectionBoxes).map(
    (sectionBox) => sectionBox.style.boxShadow
  );

  // possible photos(remove slider on pdf generate time)

  const gridElement = mainRoot.querySelector(".ds-phone-grid");
  const sliderElement = mainRoot.querySelector(".ds-phone-slider");

  const originalGridDisplay = gridElement?.style.display || "";
  const originalSliderDisplay = sliderElement?.style.display || "";

  const mapContainers = mainRoot.querySelectorAll(".map-container");

  const originalMapStates = [];

  mapContainers.forEach((mapContainer) => {
    const originalPosition = mapContainer.style.position;
    originalMapStates.push({ mapContainer, originalPosition });

    mapContainer.style.position = "relative";
    const googleMapContainer = mapContainer.querySelector(".gm-style");

    let imageMap = null;
    if (googleMapContainer) {
      imageMap = document.createElement("img");
      imageMap.src = staticMap;
      imageMap.style.position = "absolute";
      imageMap.style.top = 0;
      imageMap.style.width = "100%";
      imageMap.style.height = "100%";
      mapContainer.appendChild(imageMap);

      googleMapContainer.style.display = "none";
    }

    originalMapStates.push({
      mapContainer,
      googleMapContainer,
      imageMap,
    });
  });

  const restoreMaps = () => {
    originalMapStates.forEach(
      ({ mapContainer, googleMapContainer, imageMap, originalPosition }) => {
        if (imageMap && mapContainer.contains(imageMap)) {
          mapContainer.removeChild(imageMap);
          if (googleMapContainer) {
            googleMapContainer.style.display = "";
          }
        }
        mapContainer.style.position = originalPosition;
      }
    );
  };

  try {
    // SideBar For Mobile
    if (window.innerWidth < 900 && menuMobileElement) {
      menuMobileElement.style.display = "none";
    }

    // SideBar
    if (sideBarElement) {
      sideBarElement.style.position = "unset";
    }

    // sectionBoxes
    sectionBoxes.forEach((sectionBox) => {
      sectionBox.style.boxShadow = "unset";
      sectionBox.style.border = "1px solid rgba(36, 83, 123, 0.3)";
    });

    // possible photos(remove slider on pdf generate time)
    if (gridElement) {
      // Show the grid and hide the slider
      gridElement.style.display = "grid";
      if (sliderElement) {
        sliderElement.style.display = "none";
      }
    }

    const scale = 1.5; // window.devicePixelRatio * 2;
    const canvas = await html2canvas(mainRoot, {
      scale,
      useCORS: true,
      logging: true,
      allowTaint: true,
    });
    const imgData = canvas.toDataURL("image/jpeg", 1);
    const imgBytes = await fetch(imgData).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.create();
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const pdfWidth = 595;
    const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;
    const page = pdfDoc.addPage([pdfWidth, pdfHeight]);
    const image = await pdfDoc.embedJpg(imgBytes);
    page.drawImage(image, {
      x: 0,
      y: 0,
      width: pdfWidth,
      height: pdfHeight,
    });

    if (menuMobileElement) {
      menuMobileElement.style.display = originalDisplay;
    }
    if (sideBarElement) {
      sideBarElement.style.position = originalPositionSideBar;
    }
    sectionBoxes.forEach((sectionBox, index) => {
      sectionBox.style.boxShadow = originalSectionBoxShadows[index];
      sectionBox.style.border = "unset";
    });
    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: "application/pdf" });
  } finally {
    restoreMaps();
    if (gridElement) {
      gridElement.style.display = originalGridDisplay;
    }
    if (sliderElement) {
      sliderElement.style.display = originalSliderDisplay;
    }
  }
};

export const getSubscriptionName = (key) => {
  return key === "Phone Report"
    ? "phone_report"
    : key === "Unlock Full Report"
    ? "unlock_full_report"
    : key === "Pdf Download"
    ? "pdf_download"
    : key === "Phone Tracking"
    ? "tracking"
    : "";
};

export const renderValue = (value) => {
  if (typeof value === "boolean") {
    return value ? t("PR_YES") : t("PR_NO");
  }
  return value !== undefined && value !== null && value !== "" ? value : "N/A";
};

export const socialIcons = {
  facebook: socialFacebookIcon,
  skype: skypeIcon,
  telegram: telegramIcon,
  whatsapp: whatsappIcon,
  linkedin: socialLinkedinIcon,
  instagram: socialInstagramIcon,
  github: githubIcon,
  twitter: twitterIcon,
  snapchat: socialSnapchatIcon,
  dribble: dribbleIcon,
  pinterest: pinterestIcon,
  reddit: redditIcon,
  youtube: youtubeIcon,
  tikTok: tikTokIcon,
  weChat: weChatIcon,
  discord: discordIcon,
  threads: threadsIcon,
  thread: threadsIcon,
  twitch: twitchIcon,
  tumblr: tumblrIcon,
  flickr: flickrIcon,
  defaultImage: defaultSocialMedia,
};

export const DEFAULT_CENTER = { lat: 40.7128, lng: -73.935242 };

export const getSubscriptionColor = (key) => {
  return key === "Phone Report"
    ? "#008000"
    : key === "Unlock Full Report"
    ? "#24537B"
    : key === "Pdf Download"
    ? "#24537B"
    : key === "Phone Tracking"
    ? "#7770FB"
    : "";
};
const defaultColor = "#E8E8E8";
const lowRiskColor = "#76C651";
const mediumRiskColor = "#FFA426";
const highRiskColor = "#FF000099";

export const getGraphConfig = (percentage) => {
  if (percentage === 0) {
    return {
      colors: [
        defaultColor,
        defaultColor,
        defaultColor,
        defaultColor,
        defaultColor,
      ],
      riskTextColor: "#000",
    };
  } else if (percentage <= 20) {
    return {
      colors: [
        lowRiskColor,
        defaultColor,
        defaultColor,
        defaultColor,
        defaultColor,
      ],
      riskTextColor: lowRiskColor,
    };
  } else if (percentage <= 40) {
    return {
      colors: [
        lowRiskColor,
        lowRiskColor,
        defaultColor,
        defaultColor,
        defaultColor,
      ],
      riskTextColor: lowRiskColor,
    };
  } else if (percentage > 40 && percentage <= 80) {
    return {
      colors: [
        mediumRiskColor,
        mediumRiskColor,
        mediumRiskColor,
        defaultColor,
        defaultColor,
      ],
      riskTextColor: mediumRiskColor,
    };
  } else if (percentage > 80 && percentage <= 100) {
    return {
      colors: [
        highRiskColor,
        highRiskColor,
        highRiskColor,
        highRiskColor,
        highRiskColor,
      ],
      riskTextColor: highRiskColor,
    };
  }

  // Default case (this should theoretically never be reached if percentage is clamped between 0 and 100)
  return {
    colors: [
      defaultColor,
      defaultColor,
      defaultColor,
      defaultColor,
      defaultColor,
    ],
    riskTextColor: "#000",
  };
};

export const getRiskLabel = (percentage) => {
  if (percentage <= 40) {
    return "PR_RISK_LEVEL_LOW";
  } else if (percentage > 40 && percentage <= 80) {
    return "PR_RISK_LEVEL_MEDIUM";
  } else {
    return "PR_RISK_LEVEL_HIGH";
  }
};

// Modal Class for 36 Languages
export const bigLanguagesClass = () => {
  const bigLanguages = [
    "cs",
    "fr",
    "uk",
    "de",
    "hr",
    "es",
    "it",
    "is",
    "pt",
    "ga",
    "bn",
    "pt-br",
    "tr",
    "el",
    "et",
    "ja",
    "hu",
    "nl",
    "sv",
    "fi",
    "no",
    "pl",
    "ro",
    "sl",
    "bg",
    "yue",
    "ko",
    "hi",
    "id",
    "ms",
    "sr",
    "sk",
    "th",
    "da",
    "zh",
    "zh-CN",
  ];
  if (bigLanguages) {
    return "high__lang_wrapper";
  } else return "";
};

export const renderNoFoundData = (sectionName, ownerName, phoneNumber) => {
  switch (sectionName) {
    case "phone_owner_info":
      return {
        sectionTitle: t("PR_PHONE_OWNER_INFORMATION"),
        noFoundText: t("PR_PHONE_OWNER_TEXT", {
          phone_number: phoneNumber,
        }),
        description: t("PR_PHONE_OWNER_DESC"),
        subDescription: t("PR_PHONE_OWNER_DESCRIPTION_2", {
          phone_number: phoneNumber,
        }),
        monitorDesc: t("PR_MONITOR_SECTION_DESCRIPTION"),
      };
    case "emails":
      return {
        sectionTitle: t("PR_EMAIL_ADDRESS"),
        noFoundText: t("PR_POSSIBLE_EMAIL_TITLE", {
          phone_number: phoneNumber,
        }),
        description: ownerName
          ? t("PR_EMAIL_ADDRESS_DESCRIPTION_1", {
              phone_owner_full_name: ownerName,
            })
          : t("PR_EMAIL_ADDRESS_DESCRIPTION_2", {
              phone_phone_number: phoneNumber,
            }),
        monitorDesc: t("PR_EMAIL_ADDRESS_STATUS", {
          hours: "24",
        }),
      };
    case "other_phone_numbers":
      return {
        sectionTitle: t("PR_OTHER_PHONE_NUMBERS"),
        noFoundText: t("PR_OTHER_PHONE_NUMBER_SUBTITLE", {
          phone_number: ownerName ? ownerName : phoneNumber,
        }),
        description: ownerName
          ? t("PR_OTHER_PHONE_NUMBER_DESCRIPTION_1", {
              phone_owner_full_name: ownerName,
            })
          : t("PR_OTHER_PHONE_NUMBER_DESCRIPTION_2", {
              phone_number: phoneNumber,
            }),
        monitorDesc: t("PR_OTHER_PHONE_NUMBER_STATUS", {
          phone_number: phoneNumber,
        }),
      };
    case "phone_approximate_location":
      return {
        sectionTitle: t("PR_PHONE_APPROXIMATE_LOCATION"),
        noFoundText: t("PR_PHONE_APPROXIMATE_TEXT", {
          phone_number: phoneNumber,
        }),
        description: t("PR_PHONE_APPROXIMATE_DESCRIPTION_1", {
          phone_number: phoneNumber,
        }),
        subDescription: t("PR_SUB_DESCRIPTION_LOCATION", { hours: "24" }),
        monitorDesc: t("PR_PHONE_APPROXIMATE_MONITOR_DESC"),
      };
    case "possible_photos":
      return {
        sectionTitle: t("PR_POSSIBLE_PHOTOS"),
        noFoundText: t("PR_NO_INFORMATION_TITTLE_1", {
          phone_number: ownerName ? ownerName : phoneNumber,
        }),
        description: ownerName
          ? t("PR_POSSIBLE_PHOTOS_DESCRIPTION_2", {
              phone_owner_full_name: ownerName,
            })
          : t("PR_POSSIBLE_PHOTOS_DESCRIPTION_1", {
              phone_phone_number: phoneNumber,
            }),
        monitorDesc: t("PR_POSSIBLE_PHOTOS_MONITOR_DESC", {
          phone_number: phoneNumber,
        }),
      };
    case "social_media_profiles":
      return {
        sectionTitle: t("PR_SOCIAL_PROFILES"),
        noFoundText: t("PR_NO_INFORMATION_TITLE_3", {
          phone_owner_full_name: ownerName ? ownerName : phoneNumber,
        }),
        description: ownerName
          ? t("PR_SOCIAL_MEDIA_DESCRIPTION_2", {
              number: "2",
              phone_owner_full_name: ownerName,
            })
          : t("PR_SOCIAL_MEDIA_DESCRIPTION_1", {
              number: "2",
              phone_number: phoneNumber,
            }),
        monitorDesc: t("PR_SOCIAL_MEDIA_MONITOR_DESC", {
          phone_number: phoneNumber,
        }),
      };
    case "possible_jobs":
      return {
        sectionTitle: t("PR_PREMIUM_SECTION_TITLE_2"),
        noFoundText: t("PR_EMPLOYMENT_HISTORY", {
          phone_number: ownerName ? ownerName : phoneNumber,
        }),
        description: ownerName ? t("PR_HISTORY_TITLE_1", {
          phone_owner_full_name: ownerName,
        }) : t("PR_HISTORY_TITLE_2", {
          phone_number: phoneNumber,
        }),
        monitorDesc: t("PR_MONITOR_UPDATE_REPORT_1", {
          hours: "24",
          phone_number: phoneNumber,
        }),
      };
    case "possible_educations":
      return {
        sectionTitle: t("PR_EDUCATIONAL_BACKGROUND"),
        noFoundText: t("PR_POSSIBLE_EDUCTION_TITLE", {
          phone_owner_full_name: ownerName,
        }),
        description: ownerName ? t("PR_ADDRESS_HISTORY_SUBTITLE_3", {
          phone_owner_full_name: ownerName,
        }) : t("PR_POSSIBLE_EDUCTION_DESCRIPTION_2", {
          phone_phone_number: phoneNumber,
        }),
        monitorDesc: t("PR_POSSIBLE_EDUCTION_MONITOR_DESC", {
          phone_phone_number: phoneNumber,
        }),
      };
    case "address_history":
      return {
        sectionTitle: t("PR_ADDRESS_HISTORY"),
        noFoundText: t("PR_ADDRESS_HISTORY_TITLE", {
          phone_owner_full_name: ownerName ? ownerName : phoneNumber,
        }),
        description: ownerName ? t("PR_ADDRESS_HISTORY_SUBTITLE_2", {
          phone_owner_full_name: ownerName,
        }) : t("PR_ADDRESS_HISTORY_SUBTITLE_1", {
          phone_phone_number: phoneNumber,
        }),
        monitorDesc: t("PR_ADDRESS_MONITOR_REPORT_DESCRIPTION", {
          hours: "24",
        }),
      };
    case "possible_associates":
      return {
        sectionTitle: t("PR_PREMIUM_SECTION_TITLE_4"),
        noFoundText: t("PR_RELATIONSHIP_TITLE", {
          phone_owner_full_name: ownerName ? ownerName : phoneNumber,
        }),
        description: t("PR_RELATIONSHIP_DESCRIPTION", {
          phone_owner_full_name: ownerName ? ownerName : phoneNumber,
        }),
        monitorDesc: t("PR_MONITOR_UPDATE_REPORT_2", { hours: "24" }),
      };
    ///////////
    case "name_and_ancestry":
      return {
        sectionTitle: t("PR_PREMIUM_SECTION_TEXT"),
        noFoundText: t("PR_NOT_FOUND_NAME_ANCESTRY_TEXT", {
          phone_number: phoneNumber,
        }),
        description: t("PR_NOT_FOUND_NAME_ANCESTRY_DESC"),
        subDescription: t("PR_NOT_FOUND_NAME_ANCESTRY_DESC_2", {
          phone_number: phoneNumber,
        }),
        monitorDesc: t("PR_MONITOR_SECTION_DESCRIPTION"),
      };
    case "data_leak_checker":
      return {
        sectionTitle: t("PR_PREMIUM_SECTION_TITLE_4"),
        noFoundText: t("PR_NOT_FOUND_DATA_LEAK_TEXT", {
          phone_number: phoneNumber,
        }),
        description: t("PR_NOT_FOUND_DATA_LEAK_DESC"),
        subDescription: t("PR_NOT_FOUND_DATA_LEAK_DESC_2", {
          phone_number: phoneNumber,
        }),
        monitorDesc: t("PR_MONITOR_SECTION_DESCRIPTION"),
      };
    // case "other_information":
    //   return {
    //     sectionTitle: "Other Information",
    //     noFoundText:
    //       "No Other Information was found associated with (819) 674-0000",
    //     description:
    //       "Tracelo automatically scans, each month, national and local databases, including social media and our system will automatically update this report.",
    //     monitorDesc: t("PR_MONITOR_SECTION_DESCRIPTION"),
    //   };
    default:
      return {};
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const formateTime = (createdAt) => {
  const now = moment();
  const createdMoment = moment(createdAt);
  const diffInMinutes = now.diff(createdMoment, "minutes");
  const diffInHours = now.diff(createdMoment, "hours");

  if (diffInMinutes < 1) {
    return t("PR_JUST_NOW");
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${t("PR_MIN_AGO")}`;
  } else if (diffInHours < 24) {
    return `${diffInHours} ${
      diffInHours > 1 ? t("PR_HOURS") : t("PR_HOUR")
    } ago`;
  } else {
    return createdMoment.format("DD MMMM YYYY");
  }
};

export const bigLanguages = [
  "cs",
  "fr",
  "uk",
  "de",
  "hr",
  "es",
  "it",
  "is",
  "pt",
  "ga",
  "bn",
  "pt-br",
  "tr",
  "el",
  "et",
  "ja",
  "hu",
  "nl",
  "sv",
  "fi",
  "no",
  "pl",
  "ro",
  "sl",
  "bg",
  "yue",
  "ko",
  "hi",
  "id",
  "ms",
  "sr",
  "sk",
  "th",
  "da",
  "zh",
  "zh-CN",
];
