import React, { useState } from "react";
import { useSelector } from "react-redux";
import { pdfIcon, premiumToggleBar } from "../../../assets/images";
import { useDownloadReport } from "../../../hooks/useDownloadReport";
import { renderRTLClass } from "../../../../utils/commonUtils";
import { Dropdown } from "antd";
import { t } from "i18next";

const SectionAction = () => {
  const phoneReportDetails = useSelector(
    (state) => state.getPhoneReportDetails?.getPhoneReportDetailsData?.data
  );

  const { phoneReport } = phoneReportDetails || {};
  const { handleDownloadReport, PdfActionModal } = useDownloadReport();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleToggleDropdown = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  const handleOptionClick = () => {
    handleDownloadReport(phoneReport?._id);
    setDropdownVisible(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="mobile_menu_option" onClick={handleOptionClick}>
          <img src={pdfIcon} alt="" />
          <span>{t("PR_DOWNLOAD_PDF")}</span>
        </div>
      ),
    },
  ];

  return (
    <>
      {PdfActionModal}

      <Dropdown
        menu={{ items }}
        placement="bottomRight"
        trigger={["click"]}
        open={dropdownVisible}
        onOpenChange={setDropdownVisible}
        overlayClassName={`dropdown-setting ${renderRTLClass()} download_PDF_dropdown_menu_option `}
      >
        <div>
          <div className="premium_toggle" onClick={handleToggleDropdown}>
            <a href="javascript:void(0)">
              <img src={premiumToggleBar} alt="Toggle Premium" />
            </a>
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default SectionAction;
